import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import firebase from 'firebase/app';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { collections, PlanListType, PLAN_STATUS } from 'src/app/constants';

import { Guest, Plan } from '../models/index';

import { AuthService } from './auth.service';

@Injectable()
export class PlansService {
  cachedPlans: Plan[];

  constructor(private platform: Platform, private authService: AuthService, private afs: AngularFirestore) {}

  getList(type: PlanListType, limit?: number, userId?: string) {
    if (!userId) {
      userId = this.authService.userId;
    }
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const todayUnix = Math.floor(todayDate.getTime() / 1000);

    const dateComparator = type === PlanListType.PAST ? '<' : '>=';
    const dateSort = type === PlanListType.PAST ? 'desc' : 'asc';

    return this.afs
      .collection<Guest>(collections.GUESTS, (ref) => {
        return ref
          .where('userId', '==', userId)
          .where('dateUnix', dateComparator, todayUnix)
          .where('tenantId', '==', this.authService.tenantId)
          .orderBy('dateUnix', dateSort)
          .limit(limit || 50);
      })
      .valueChanges({
        idField: 'objectId',
      });
  }

  getAllHistory() {
    const userId = this.authService.userId;
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const todayUnix = Math.floor(todayDate.getTime() / 1000);

    const result: Observable<any[]> = this.afs
      .collection(collections.PLANS, (ref) => {
        return ref
          .where('status', '==', PLAN_STATUS.PUBLISHED)
          .where('createdBy', '==', userId)
          .where('tenantId', '==', this.authService.tenantId)
          .where('dateUnix', '<', todayUnix)
          .orderBy('dateUnix', 'desc')
          .limit(100);
      })
      .valueChanges({
        idField: 'objectId',
      });
    return result;
  }

  getCommunityEvents(communityId: string, type: PlanListType) {
    const user = this.authService.user;
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const todayUnix = Math.floor(todayDate.getTime() / 1000);

    const dateComparator = type === PlanListType.PAST ? '<' : '>=';
    const dateSort = type === PlanListType.PAST ? 'desc' : 'asc';

    return this.afs
      .collection(collections.PLANS, (ref) => {
        let query = ref
          .where('status', '==', PLAN_STATUS.PUBLISHED)
          .where('organizerId', '==', communityId)
          .where('dateUnix', dateComparator, todayUnix)
          .where('tenantId', '==', this.authService.tenantId)
          .orderBy('dateUnix', dateSort);

        if (!user) {
          query = query.where('isPublic', '==', true);
        }

        return query;
      })
      .valueChanges({
        idField: 'objectId',
      });
  }

  getGuestList(planId: string) {
    const result: Observable<any[]> = this.afs
      .collection(collections.GUESTS, (ref) => {
        return ref.where('planId', '==', planId).where('tenantId', '==', this.authService.tenantId).orderBy('name');
      })
      .valueChanges();
    return result;
  }

  guestObjectForPlan(plan: any, planId: string, objectId: string, isSelf: boolean, object: any = {}) {
    const user = this.authService.user;
    const shortId = objectId.substr(objectId.length - 5, 4);
    const metadata: any = {
      planTitle: plan.title,
      planLocation: plan.location,
      planUtcOffset: plan.utcOffset,
      creatorName: user.displayName,
      creatorPhoto: user.photoURL || '',
    };

    if (plan.photo) {
      metadata.planPhotoURL = plan.photo;
    }

    if (plan.photoPath) {
      metadata.planPhotoPath = plan.photoPath;
    }

    const isWaiting = !isSelf || plan.status === PLAN_STATUS.DRAFT;
    const guest: Guest = {
      ...object,
      planId,
      date: plan.date,
      dateUnix: plan.dateUnix,
      isHost: isSelf ? true : false,
      rsvp: isWaiting ? 'waiting' : 'attending',
      sid: shortId,
      createdBy: this.authService.userId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      metadata,
      createdDeviceInfo: this.getDeviceObject(),
      chatNotificationPreference: 'all',
      newGuestNotificationEnabled: true,
      tenantId: plan.tenantId,
      _plan: plan,
    };

    if (plan.endDateUnix) {
      guest.endDateUnix = plan.endDateUnix;
    }

    if (plan.endDate) {
      guest.endDate = plan.endDate;
    }

    if (isSelf) {
      guest.name = user.displayName;
      guest.phoneNumber = user.phoneNumber;
      guest.phoneFormatted = user.phoneNumber;
      guest.email = user.email;
      guest.userId = this.authService.userId;
      guest.seenAt = firebase.firestore.FieldValue.serverTimestamp();

      if (this.authService.profile) {
        guest.photo = this.authService.profile.photo || '';
        guest.deviceToken = this.authService.profile.deviceToken || '';
      }
    }

    return guest;
  }

  padDateTime(element) {
    return String(element).padStart(2, '0');
  }

  getUtcDate(inputDate: Date, inputTime?: string, timezone?: string) {
    if (!inputDate) {
      return undefined;
    }

    if (!inputTime) {
      return inputDate;
    }

    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const [time, suffix] = inputTime.split(' ');
    const [hour, minute] = time.split(':');
    const dateString = `${year}-${this.padDateTime(month)}-${this.padDateTime(day)} ${this.padDateTime(
      hour
    )}:${this.padDateTime(minute)} ${suffix}`;

    const date = timezone ? moment.utc(dateString, 'YYYY-MM-DD HH:mm A') : moment(dateString, 'YYYY-MM-DD HH:mm A');

    if (timezone) {
      date.add(moment.tz.zone(timezone).utcOffset(date.valueOf()), 'minutes');
    }

    return date.toDate();
  }

  getDeviceObject() {
    return this.getDeviceInfo(this.platform);
  }

  getPlanLink(planId: string) {
    // const subdomain = this.authService.subdomain ? `${this.authService.subdomain}.` : '';
    // const url = new URL(environment.baseUrl);
    // url.host = `${subdomain}${url.host}`;

    // We need to handle custom subdomains as well as custom domains
    // Could be the b2c everyspace site at app.everyspacehq.com
    // Could be {client}.everyspacehq.com
    // Or it could be events.client.com or mycustomdomain.com
    // The correct way to do this is to use the company-domain customDomain property
    // For now let's just use the URL of the browser
    const origin = window.location.origin;

    return `${origin}/events/${planId}`;
  }

  getDeviceInfo(platform: Platform) {
    /*
     * | Platform Name   | Description                        |
     * |-----------------|------------------------------------|
     * | android         | on a device running Android.       |
     * | cordova         | on a device running Cordova.       |
     * | core            | on a desktop device.               |
     * | ios             | on a device running iOS.           |
     * | ipad            | on an iPad device.                 |
     * | iphone          | on an iPhone device.               |
     * | mobile          | on a mobile device.                |
     * | mobileweb       | in a browser on a mobile device.   |
     * | phablet         | on a phablet device.               |
     * | tablet          | on a tablet device.                |
     * | windows         | on a device running Windows.       |
     * | electron        | in Electron on a desktop device.   |
     */
    return {
      platforms: platform.platforms(),
    };
  }
}
