import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { CompanyPrivacyGuard } from './company-privacy.guard';
import { SuperAdminGuard } from './super-admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'plans',
    loadChildren: () => import('./plans/plans.module').then((m) => m.PlansPageModule),
  },
  {
    path: 'p',
    loadChildren: () => import('./plans/plans.module').then((m) => m.PlansPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'events',
    loadChildren: () => import('./plans/plans.module').then((m) => m.PlansPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./discover/discover.module').then((m) => m.DiscoverPageModule),
    canActivate: [CompanyPrivacyGuard],
  },
  {
    path: 'home/community/:communityId',
    loadChildren: () => import('./discover/discover.module').then((m) => m.DiscoverPageModule),
    canActivate: [CompanyPrivacyGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'profiles/:profileId',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'people/:profileId',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'people/:type/:profileId',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'people',
    loadChildren: () => import('./people/people.module').then((m) => m.PeoplePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'communities',
    loadChildren: () =>
      import('./communities/community-list/community-list.module').then((m) => m.CommunityListPageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'communities/:communityId',
    loadChildren: () =>
      import('./communities/community-details/community-details.module').then((m) => m.CommunityDetailsPageModule),
  },
  {
    path: 'communities/:communityId/:actionType',
    loadChildren: () =>
      import('./communities/community-details/community-details.module').then((m) => m.CommunityDetailsPageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./experiences/experiences.module').then((m) => m.ExperiencesPageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'posts/new',
    loadChildren: () => import('./post/post.module').then((m) => m.PostPageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'posts/preview',
    loadChildren: () => import('./post/post.module').then((m) => m.PostPageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'posts/:postId/duplicate',
    loadChildren: () => import('./post/post.module').then((m) => m.PostPageModule),
  },
  {
    path: 'posts/:postId',
    loadChildren: () => import('./post/post.module').then((m) => m.PostPageModule),
  },
  {
    path: 'login/:authProvider',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  // Need a new upgrade page...
  // {
  //   path: 'upgrade',
  //   loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
  //   canActivate: [CompanyPrivacyGuard, AuthGuard],
  // },
  {
    path: 'integrations/:integrationType',
    loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsPageModule),
    // canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'manage-event',
    loadChildren: () => import('./plan-admin/plan-admin.module').then((m) => m.PlanAdminPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-recurring-event',
    loadChildren: () => import('./plan-admin/plan-admin.module').then((m) => m.PlanAdminPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'post-event/:planId',
    loadChildren: () => import('./plan-feedback/plan-feedback.module').then((m) => m.PlanFeedbackPageModule),
  },
  {
    path: 'manage-community',
    loadChildren: () =>
      import('./communities/community-admin/community-admin.module').then((m) => m.CommunityAdminPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'post',
    loadChildren: () => import('./post/post.module').then((m) => m.PostPageModule),
  },
  {
    path: 'slack-message',
    loadChildren: () => import('./slack-message/slack-message.module').then((m) => m.SlackMessagePageModule),
  },
  // {
  //   path: 'upgrade',
  //   loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
  // },
  // {
  //   path: 'subscription',
  //   loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
  // },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
