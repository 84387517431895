<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <ion-button slot="icon-only" fill="clear" class="close-button" (click)="dismissModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="feedback">
  <h3>{{title}}</h3>
  <ion-textarea placeholder="{{placeholder}}" [(ngModel)]="message" class="input-field"></ion-textarea>
  <br>
  <ion-button (click)="submitFeedback()">
    Send
  </ion-button>
  <ion-button fill="clear" (click)="dismissModal()">
    Cancel
  </ion-button>
</ion-content>