import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Plugins, Capacitor } from '@capacitor/core';
import { Platform, NavController, PopoverController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as _ from 'lodash';
import * as moment from 'moment';

import 'rxjs/add/operator/map';

import { environment } from 'src/environments/environment';
import { Profile } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { AuthService } from 'src/services/auth.service';
import { MessageService } from 'src/services/message.service';

import { ActionDropdownComponent } from './components/action-dropdown/action-dropdown.component';
import { collections, POST_TYPE, routes } from './constants';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfilePopoverPage } from './profile/profile-popover/profile-popover.page';
import { hexToRgb } from './utils/utils';

const { PushNotifications, App } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  profile: Profile;
  buttonsDisabled = false;
  logo: string;
  isReady = false;
  notifications: any[] = [];
  unreadNotifications = 0;
  hideNavBar = false;
  logoLink: string;
  trialStatus: string;
  trialExpired: boolean;

  public environment = environment;
  public appPages = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private navController: NavController,
    private popoverCtrl: PopoverController,
    private msgSrvc: MessageService,
    private alertCtrl: AlertController,
    public authService: AuthService,
    private analyticsService: AnalyticsService
  ) {
    this.appPages = [
      {
        title: 'Home',
        url: `/${routes.HOME}`,
        icon: 'home',
        private: false,
      },
      {
        title: 'Events',
        url: `/${routes.EVENTS}`,
        icon: 'calendar',
        private: false,
      },
      {
        title: 'Communities',
        url: `/${routes.COMMUNITIES}`,
        icon: 'people-circle',
        private: false,
      },
    ];
    this.logoLink = 'home';

    this.initializeApp();

    this.authService.onAuthReady(() => {
      this.configureForTenant();
      this.subscribeToNotifications();
    });

    if (Capacitor.isNative) {
      App.addListener('appStateChange', () => {
        PushNotifications.removeAllDeliveredNotifications();
      });

      PushNotifications.addListener('pushNotificationActionPerformed', this.handlePushAction);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    // TODO: For newco - Add a new google analytics API key to re-enable this functionality. See the environtment files.
    if (environment.googleAnalyticsTrackingId) {
      // Start analytics
      this.analyticsService.init(environment.googleAnalyticsTrackingId);
    }

    this.route.queryParams.subscribe((params) => {
      // Janky way of seeing if we're redirecting for oauth in the app.everyspacehq.com domain
      this.hideNavBar = !!params.redirectForOAuth && !this.authService.tenantId;
    });
  }

  async configureForTenant() {
    this.logo = this.authService.logo;
    const primaryColor = this.authService.primaryColor;
    const tenant = this.authService.tenant;
    const isCompanyAdmin = this.authService.isCompanyAdmin;

    if (primaryColor) {
      document.body.style.setProperty(`--ion-color-primary`, primaryColor);
      document.body.style.setProperty(`--ion-color-primary-rgb`, hexToRgb(primaryColor));
    }

    if (tenant?.accountStatus === 'trial') {
      if (tenant.trialEndDate) {
        const end = moment(tenant.trialEndDate);
        this.trialExpired = end.diff(moment()) < 0;
        this.trialStatus = this.trialExpired
          ? `Trial expired. Click to upgrade`
          : `Trial expires ${moment(tenant.trialEndDate).fromNow()}`;
      } else {
        this.trialStatus = 'Trial period';
        this.trialExpired = false;
      }
    }

    if (this.authService.user) {
      // Determine if they're an admin; they'll only be able to retrieve this doc if they are
      const canViewPeople = _.get(this.authService, 'permissions.canViewPeople', true);

      if (canViewPeople || isCompanyAdmin) {
        this.appPages.push({
          title: 'People',
          url: `/${routes.PEOPLE}`,
          icon: 'person',
          private: !canViewPeople,
        });
      }

      // Do some custom handling for tenant admins
      // Marketplace should only be available to admins; in the future, we'll want to control this with permissions
      if (isCompanyAdmin) {
        if (this.authService.subdomain === 'piedpiper') {
          this.appPages.push({
            title: 'Marketplace',
            url: `/${routes.MARKETPLACE}`,
            icon: 'basket',
            private: true,
          });
        }

        this.appPages.push({
          title: 'Admin',
          url: `/${routes.COMPANY_ADMIN}`,
          icon: 'analytics',
          private: true,
        });
      }

      if (this.authService.isSuperAdmin && _.get(tenant, 'isInternal')) {
        this.appPages.push({
          title: 'Super Admin',
          url: `/${routes.SUPER_ADMIN}`,
          icon: 'skull',
          private: true,
        });
      }
    }

    this.isReady = true;
  }

  async subscribeToNotifications() {
    if (!this.authService.user) {
      return;
    }

    this.afs
      .collection(collections.NOTIFICATIONS, (ref) =>
        ref
          .where('userId', '==', this.authService.userId)
          .where('tenantId', '==', this.authService.tenantId)
          .limit(25)
          .orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'objectId' })
      .subscribe(
        (val) => {
          this.notifications = val;

          let notifs = 0;
          this.notifications.forEach((n) => {
            if (!n.read) {
              notifs++;
            }
          });
          this.unreadNotifications = notifs;
        },
        (err) => {
          console.log('Error getting notifications: ', err);
        }
      );
  }

  async showCreateOptions() {
    const isCompanyAdmin = this.authService.isCompanyAdmin;
    const canCreateCommunity = _.get(this.authService, 'permissions.canCreateOrganizers', true);

    const CREATE_EVENT = 'Create event';
    const CREATE_POST = 'Create post';
    const CREATE_SURVEY = 'Create survey';
    const CREATE_COMMUNITY = 'Create community';

    const options = [
      {
        label: CREATE_EVENT,
        icon: 'calendar-outline',
        id: 'global-create-event-btn',
      },
      {
        label: CREATE_POST,
        icon: 'document-text-outline',
        id: 'global-create-post-btn',
      },
      {
        label: CREATE_SURVEY,
        icon: 'list',
        id: 'global-create-poll-btn',
      },
    ];

    if (canCreateCommunity || isCompanyAdmin) {
      options.push({
        label: CREATE_COMMUNITY,
        icon: 'people-circle-outline',
        id: 'global-create-community-btn',
      });
    }

    if (!options.length) {
      this.msgSrvc.show('🔒 Contact an admin to create events or posts');
      return;
    }

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (_index: number, label: string) => {
          popover.dismiss();

          if (label === CREATE_EVENT) {
            this.navController.navigateForward(`${routes.EVENTS}/new`);
          } else if (label === CREATE_POST) {
            this.navController.navigateForward(`${routes.POSTS}/new`);
          } else if (label === CREATE_SURVEY) {
            this.navController.navigateForward(`${routes.POSTS}/new?type=${POST_TYPE.SURVEY}`);
          } else if (label === CREATE_COMMUNITY) {
            this.navController.navigateForward(`${routes.COMMUNITIES}/new`);
          }
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async openProfile() {
    const popover = await this.popoverCtrl.create({
      component: ProfilePopoverPage,
      componentProps: {},
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async openNotifications() {
    const popover = await this.popoverCtrl.create({
      component: NotificationsComponent,
      componentProps: {
        notifications: this.notifications,
        unreadCount: this.unreadNotifications,
      },
      showBackdrop: false,
      event,
      cssClass: 'notifications-popover',
    });
    popover.present();
  }

  loginOrSignup() {
    if (this.router.routerState.snapshot.url.startsWith('/login')) {
      return;
    }

    this.navController.navigateRoot(['/login'], {
      queryParams: {
        redirect: this.router.routerState.snapshot.url,
      },
    });
  }

  handlePushAction(action: any) {
    const notif = action.notification;

    if (notif) {
      const planId = _.get(notif, 'data.planId');
      // const type = _.get(notif, 'data.alertType');
      // const guestId = _.get(notif, 'data.guestId');

      if (planId) {
        this.navController.navigateForward(`/events/${planId}`);
      }
    }
  }

  returnToLogin() {
    this.authService.logout();
  }

  async resendVerificationEmail() {
    await this.authService.sendVerificationEmailForUser(this.authService.user);
    this.msgSrvc.show('✓ Verification email sent');
  }
}
