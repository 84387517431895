import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { SlackBlocksModule } from 'src/app/components/slack-blocks/slack-blocks.module';

import { MessageAnalyticsPage } from './message-analytics/message-analytics.page';
import { MessagingComponent } from './messaging.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, NgxDatatableModule, SlackBlocksModule, NgxTippyModule],
  declarations: [MessagingComponent, MessageAnalyticsPage],
  exports: [MessagingComponent, MessageAnalyticsPage],
})
export class MessagingModule {}
