import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import firebase from 'firebase/app';

import { AuthService } from 'src/services/auth.service';
import { MessageService } from 'src/services/message.service';

import { collections } from '../constants';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage {
  message = '';
  title = "Let us know how we're doing";
  placeholder = 'Enter your feedback here';

  constructor(
    private afs: AngularFirestore,
    private msgSrvc: MessageService,
    public modalCtrl: ModalController,
    navParams: NavParams,
    private authService: AuthService
  ) {
    const title = navParams.get('title');
    const placeholder = navParams.get('placeholder');

    if (title) {
      this.title = title;
    }
    if (placeholder) {
      this.placeholder = placeholder;
    }
  }

  submitFeedback() {
    const feedback: any = {
      message: this.message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      tenantId: this.authService.tenantId,
    };

    const user = this.authService.user;
    if (user) {
      feedback.name = user.displayName;
      feedback.createdBy = this.authService.userId;
    }

    this.afs
      .collection(collections.FEEDBACKS)
      .doc(this.afs.createId())
      .set(feedback)
      .then(() => {
        this.dismissModal();
      })
      .catch((err) => {
        this.msgSrvc.show('Unable to save feedback: ' + err.message);
      });
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
