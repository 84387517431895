import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from 'src/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyPrivacyGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    await new Promise((resolve) => {
      this.authService.onAuthReady(() => {
        resolve(undefined);
      });
    });

    if (this.authService.tenantId && !this.authService.user) {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect: route.url.join('/'),
        },
      });

      return false;
    }

    return true;
  }
}
