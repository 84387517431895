import { AngularEditorConfig } from '@kolkov/angular-editor';

import { environment } from 'src/environments/environment';

export const collections = {
  GUESTS: 'guests',
  PLANS: 'plans',
  UPLOADS: 'uploads',
  CATALOG_EXPERIENCES: 'catalog-experiences',
  ORGANIZERS: 'organizers',
  FEATURED_POSTS: 'featured-posts',
  POSTS: 'posts',
  POST_FOLLOWS: 'post-follows',
  POST_REACTIONS: 'post-reactions',
  COMMENT_REACTIONS: 'comment-reactions',
  COMMENTS: 'comments',
  REMINDERS: 'reminders',
  INVITE_PREVIEWS: 'invite-previews',
  NOTIFICATIONS: 'notifications',
  FEEDBACKS: 'feedbacks',
  ONBOARDING_PLANS: 'onboarding-plans',
};

export const routes = {
  HOME: 'home',
  EVENTS: 'events',
  EVENT_ADMIN: 'manage-event',
  RECURRING_EVENT_ADMIN: 'manage-recurring-event',
  COMMUNITIES: 'communities',
  COMMUNITY_ADMIN: 'manage-community',
  POSTS: 'posts',
  PEOPLE: 'people',
  MARKETPLACE: 'marketplace',
  COMPANY_ADMIN: 'admin',
  INTEGRATIONS: 'integrations',
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  SLACK_MESSAGE: 'slack-message',
  DASHBOARD: 'dashboard',
  OUTBOUND: 'outbound',
};

export const PLAN_DATE_STATUS = {
  POLLING: 'polling',
  CONFIRMED: 'confirmed',
};

export const PLAN_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export enum PlanListType {
  CURRENT,
  PAST,
}

export const PLAN_VENUE_STATUS = {
  SEARCHING: 'searching',
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
};

export const AUTH_CONTEXT = {
  DEFAULT: 'default',
  RSVP: 'rsvp',
  EXPERIENCE: 'experience',
  FOLLOW: 'follow',
  REDIRECT: 'redirect',
};

export const AUTH_PROVIDERS = {
  OKTA: 'okta',
  GSUITE: 'gsuite',
  PASSWORD: 'password',
};

export const RSVP_STATUS = {
  WAITING: 'waiting',
  MAYBE: 'maybe',
  DECLINED: 'declined',
  ATTENDING: 'attending',
  INTERESTED: 'interested',
  NOT_INTERESTED: 'not_interested',
  WAITLISTED: 'waitlisted',
};

export const EXPORT_HEADERS_PLANS = [
  'id',
  'title',
  'date',
  'location',
  'createdAt',
  'description',
  'timezone',
  'createdBy',
  'organizerId',
  'attendingCount',
];

export const EXPORT_HEADERS_GUESTS = ['id', 'name', 'email', 'phoneNumber', 'rsvp', 'createdAt', 'planId', 'isHost'];

export const EXPORT_HEADERS_ORGANIZERS = [
  'id',
  'name',
  'category',
  'description',
  'followerCount',
  'createdAt',
  'createdBy',
];

export const EXPORT_HEADERS_BUDGETS = ['community', 'communityId', 'year', 'budget', 'spend', 'remaining', 'currency'];

export const EXPORT_HEADERS_EXPENSES = [
  'community',
  'communityId',
  'id',
  'externalId',
  'date',
  'amount',
  'title',
  'description',
  'eventId',
  'userId',
  'currency',
];

export const CHAT_NOTIFICATION_PREFERENCE = {
  ALL: 'all',
  OFF: 'off',
  HOST_ONLY: 'host-only',
};

export const GUEST_LIST_SHOWN = {
  ALWAYS: 'always',
  RSVPED: 'rsvped',
  NEVER: 'never',
};

export const POST_TYPE = {
  TEXT: 'text',
  SURVEY: 'survey',
};

export const POST_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
};

export const POST_REACTION_OPTIONS = [
  {
    label: 'Like',
    value: 'like',
    icon: 'thumbs-up',
  },
  {
    label: 'Dislike',
    value: 'dislike',
    icon: 'thumbs-down',
  },
  {
    label: 'Insightful',
    value: 'insightful',
    icon: 'bulb',
  },
  {
    label: 'Heart',
    value: 'heart',
    icon: 'heart',
  },
  {
    label: 'Exciting',
    value: 'exciting',
    icon: 'rocket',
  },
];

export const COMMUNITY_CATEGORIES = [
  {
    displayName: 'Club',
    key: 'club',
  },
  {
    displayName: 'Cultural group',
    key: 'erg',
  },
  {
    displayName: 'Team',
    key: 'team',
  },
  {
    displayName: 'Department',
    key: 'department',
  },
  {
    displayName: 'Office',
    key: 'office',
  },
  {
    displayName: 'Non-profit',
    key: 'nonProfit',
  },
  {
    displayName: 'Resource',
    key: 'resource',
  },
  {
    displayName: 'Project',
    key: 'project',
  },
  {
    displayName: 'Cohort',
    key: 'cohort',
  },
  {
    displayName: 'Venue',
    key: 'venue',
  },
  {
    displayName: 'Other',
    key: 'other',
  },
];

// This is kinda duplicated from above – should do this better
export const COMMUNITY_CATEGORY_MAP = {
  club: 'Clubs',
  erg: 'Cultural groups',
  team: 'Teams',
  cohort: 'Cohorts',
  department: 'Departments',
  office: 'Offices',
  venue: 'Venues',
  other: 'Other',
  nonProfit: 'Non-profits',
  resource: 'Resources',
  project: 'Projects',
};

export const COMMUNITY_MEMBER_LIST_PRIVACY = {
  ALWAYS: 'always',
  MEMBERS: 'members',
  ADMINS: 'admins',
};

export const CUSTOM_FIELD_TYPE_SHORT_ANSWER = 'short-answer';
export const CUSTOM_FIELD_TYPE_LONG_ANSWER = 'long-answer';
export const CUSTOM_FIELD_TYPE_CHECKBOX = 'checkbox';
export const CUSTOM_FIELD_TYPE_DROPDOWN = 'dropdown';
export const CUSTOM_FIELD_TYPE_MULTIPLE_CHOICE = 'multiple-choice';
export const CUSTOM_FIELD_TYPE_MULTISELECT = 'multiselect';
export const CUSTOM_FIELD_TYPE_FILE_UPLOAD = 'file-upload';
export const CUSTOM_FIELD_TYPE_USER_ATTRIBUTE = 'user-attribute';

export const HOME_FEED_FILTERS = {
  all: {
    display: 'All upcoming',
    icon: 'pulse',
    key: 'all',
  },
  // announcements: {
  //     display: 'Announcement',
  //     icon: 'megaphone',
  //     key: 'announcements',
  // },
  posts: {
    display: 'Discussions',
    icon: 'chatbox-ellipses',
    key: 'posts',
  },
  ergEvents: {
    display: 'Cultural events',
    icon: 'globe',
    key: 'ergEvents',
  },
  clubEvents: {
    display: 'Club events',
    icon: 'basketball',
    key: 'clubEvents',
  },
  pastEvents: {
    display: 'Past events',
    icon: 'reload-circle',
    key: 'pastEvents',
  },
};

export const UPLOAD_STATUS = {
  NONE: 'none',
  PROCESSING: 'processing',
  FAILED: 'failed',
  SUCCESS: 'success',
};

export const TIME_INTERVAL_SECONDS = {
  ONE_HOUR: 3600,
  ONE_DAY: 86400,
  TWO_DAYS: 172800,
  ONE_WEEK: 604800,
};

export const MAX_FILE_SIZE_MB = 5; // MB

export const ANGULAR_EDITOR_CONFIG_DEFAULT: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter text here...',
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['undo', 'redo', 'strikeThrough', 'fontName', 'subscript', 'superscript', 'justifyFull'],
    [
      'fontSize',
      'unlink',
      'insertHorizontalRule',
      'backgroundColor',
      'removeFormat',
      'insertVideo',
      'toggleEditorMode',
    ],
  ],
};

export const USER_NOTIFICATION_TYPES = {
  NEW_INVITE: 'newInvite',
  EVENT_UPDATED: 'planUpdated',
  EVENT_CANCELED: 'planCanceled',
  EVENT_CHAT_RECEIVED: 'chatReceived',
  GUEST_RSVP_UPDATED: 'rsvpUpdated',
  GUEST_SURVEY_UPDATED: 'surveyUpdated',
  COMMUNITY_MEMBER_FOLLOW: 'communityMemberFollow',
  COMMUNITY_MEMBER_UNFOLLOW: 'communityMemberUnfollow',
  COMMUNITY_MEMBER_UPLOAD_SUCCEEDED: 'communityMemberUploadSucceeded',
  COMMUNITY_MEMBER_UPLOAD_FAILED: 'communityMemberUploadFailed',
  SURVEY_RESPONSE_UPDATED: 'surveyResponseUpdated',
};

export const ZOOM_OAUTH_URL = 'https://zoom.us/oauth/authorize';
export const ZOOM_OAUTH_REDIRECT_URL = `${environment.baseUrl}/integrations/zoom`;
export const ZOOM_AUTH_CODE_PARAM = 'code';

export const VIDEOCONFERENCE_PROVIDERS = {
  ZOOM: 'zoom',
  MEET: 'hangoutsMeet',
};

export const INTEGRATIONS = {
  zoom: {
    everyspaceEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Fzoom.jpg?alt=media&token=a881c38e-4f2c-4711-803f-b0ef1d6cccc7',
    url: 'zoom',
    key: 'zoom',
    name: 'Zoom',
    title: 'Get more out of your events with Zoom',
    bullets: [
      'Automatically create Zoom meeting links for each event',
      'Sync your existing Zoom meetings into events',
      'Sync contacts for easy invitations',
    ],
    html: `
    <ul>
      <li>Automatically create Zoom meeting links for each event</li>
      <li>Sync your existing Zoom meetings into eventsy</li>
      <li>Sync contacts for easy invitations</li>
    </ul>
    `,
    connect: 'Connect my Zoom account',
  },
  slack: {
    everyspaceEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Fslack.jpg?alt=media&token=3f44f71a-8181-4389-b96a-999426efe1bb',
    url: 'slack',
    key: 'slack',
    name: 'Slack',
    title: 'Get more out of Everyspace with Slack',
    bullets: [
      "Post events & reminders to your community's Slack channel",
      'Share posts and other updates instantly',
      'Sync your Slack members with your community member list',
    ],
    html: `
    <img src="https://i0.wp.com/everyspacehq.com/wp-content/uploads/2022/01/connector.png?resize=1024%2C486&ssl=1" />
    <strong>With Everyspace and Slack, you can:</strong>
    <ul>
      <li>Post events & reminders to your community's Slack channel</li>
      <li>Share posts and other updates instantly</li>
      <li>Sync your Slack members with your community member list</li>
    </ul>
    <img src="https://i0.wp.com/everyspacehq.com/wp-content/uploads/2022/08/slack.png?w=1054&ssl=1" />
    <strong>When creating messages for Slack:</strong>
    <ul>
      <li>See a preview of the message before it's sent</li>
      <li>Send now or schedule messages for later</li>
      <li>Send across channels or DMs</li>
      <li>Track click-throughs on the links that you share</li>
    </ul>
    <p>Click the "Add to Slack" button to get started!</p>
    `,
    connect: 'Connect my Slack account',
  },
  googleCalendar: {
    everyspaceEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Fgoogle.jpg?alt=media&token=8fcbe7b6-74c5-4132-bc09-fed2ac43ad94',
    url: 'google-calendar',
    key: 'googleCalendar',
    name: 'Google Calendar',
    title: 'Sync your events with Google Calendar',
    bullets: [
      'Share your Everyspace events to a shared calendar',
      'Sync events from a calendar into Everyspace',
      'Easily share updates and customize notifications',
    ],
    html: `
    <ul>
      <li>Share your Everyspace events to a shared calendar</li>
      <li>Sync events from a calendar into Everyspace</li>
      <li>Easily share updates and customize notifications</li>
    </ul>
    `,
    connect: 'Link to Google Calendar',
  },
  outlook: {
    everyspaceEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Foutlook.jpg?alt=media&token=a7842a61-08c5-456a-8526-0da1cd7170dc',
    url: 'outlook',
    key: 'outlook',
    name: 'Outlook',
    title: 'Sync your events with Outlook',
    bullets: [
      'Share your Everyspace events to a shared calendar',
      'Sync events from a calendar into Everyspace',
      'Easily share updates and customize notifications',
    ],
    html: `
    <ul>
      <li>Share your Everyspace events to a shared calendar</li>
      <li>Sync events from a calendar into Everyspace</li>
      <li>Easily share updates and customize notifications</li>
    </ul>
    `,
    connect: 'Connect to Outlook',
  },
  teams: {
    everyspaceEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Fteams.jpg?alt=media&token=140aba6a-4b76-4f39-b1f2-0ed7cf04d7c7',
    url: 'teams',
    key: 'teams',
    name: 'Microsoft Teams',
    title: 'Connect to Microsoft Teams',
    bullets: [
      "Post events & reminders to your community's Teams channel",
      'Share posts and other updates instantly',
      // 'Sync your Teams members with your community member list',
    ],
    html: `
    <ul>
      <li>Post events & reminders to your community's Teams channel</li>
      <li>Share posts and other updates instantly</li>
      <li>Send custom messages to your teams</li>
    </ul>
    `,
    connect: 'Connect to Teams',
  },
  workos: {
    everyspaceEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Fdirectory.jpg?alt=media&token=6475013e-baba-4182-a170-a46f0cf5da0e',
    url: 'directory-sync',
    key: 'workos',
    name: 'Directory Sync',
    title: 'Sync User Profiles into Everyspace',
    bullets: [
      'Connect your employee directory to Everyspace',
      'Employees will automatically appear in your user list',
      'Work with any provider (Okta, Azure AD, custom SCIM, etc.)',
    ],
    html: `
    <ul>
      <li>Connect your employee directory to Everyspace</li>
      <li>Employees will automatically appear in your user list</li>
      <li>Work with any provider (Okta, Azure AD, custom SCIM, etc.)</li>
    </ul>
    `,
    connect: 'Configure Directory Sync',
  },
  marketplace: {
    everyspaceEnabled: false,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fapps%2Fmarketplace.jpg?alt=media&token=6c9b81e0-f2f1-4d63-a783-d795894c5ed3',
    url: 'marketplace',
    key: 'marketplace',
    name: 'Everyspace Marketplace (Deprecated)',
    title: 'Everyspace Marketplace is no longer available',
    bullets: [
      'Browse a list of event ideas, trainings, and workshops',
      'Book instantly by entering some basic information',
      'A new tab will be added to your nav bar for admins',
    ],
    html: `
    <i>The Everyspace Marketplace has been discontinued and will no longer be visible to employees.</i>
    `,
    connect: 'Enable the Marketplace',
  },
};

export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';

export const DISCOVERABILITY_SETTINGS = {
  NONE: 'none',
  ADMIN: 'admin',
  ALL: 'all',
};

export const OUTBOUND_TYPE = {
  EMAIL: 'email',
  SLACK: 'slack',
};

export const TEMPLATE_TYPE = {
  EMAIL: 'email',
  SLACK: 'slack',
};

export const PLACEHOLDER_IMAGE_SRC =
  'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fno-image.jpeg?alt=media&token=224c9de1-7af0-4d87-b9b8-d23e140f7fa5';

export const UPLOAD_TYPE = {
  MEMBER_LIST: 'member-list',
  PROFILE_PIC: 'profile-pic',
};

export const OUTBOUND_LEVEL = {
  SYSTEM: 'system',
  TENANT: 'tenant',
  COMMUNITY: 'community',
};

export const OUTBOUND_STATUS = {
  DRAFT: 'draft',
  SENT: 'sent',
  SCHEDULED: 'scheduled',
  FAILED: 'failed',
};

export const OUTBOUND_RECIPIENT_TYPE = {
  EMAIL: 'email',
  COMMUNITY: 'community',
  USER: 'user',
};

export const COMMUNITY_MEMBER_SOURCE_TYPE = {
  ALL_PEOPLE: 'all-people',
};

export const CURRENCIES = {
  USD: {
    code: 'USD',
    name: 'US Dollar',
  },
  EUR: {
    code: 'EUR',
    name: 'European Euro',
  },
  GBP: {
    code: 'GBP',
    name: 'British Pound',
  },
  JPY: {
    code: 'JPY',
    name: 'Japanese Yen',
  },
  CHP: {
    code: 'CHP',
    name: 'Swiss Franc',
  },
  AUD: {
    code: 'AUD',
    name: 'Australian Dollar',
  },
  CAD: {
    code: 'CAD',
    name: 'Canadian Dollar',
  },
  CNY: {
    code: 'CNY',
    name: 'Chinese Yuan',
  },
  NZD: {
    code: 'NZD',
    name: 'New Zealand Dollar',
  },
  INR: {
    code: 'INR',
    name: 'Indian Rupee',
  },
  BZR: {
    code: 'BZR',
    name: 'Brazilian Real',
  },
  SEK: {
    code: 'SEK',
    name: 'Swedish Krona',
  },
  ZAR: {
    code: 'ZAR',
    name: 'South African Rand',
  },
  HKD: {
    code: 'HKD',
    name: 'Hong Kong Dollar',
  },
  MXN: {
    code: 'MXN',
    name: 'Mexican Peso',
  },
  AED: {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
  },
  SGD: {
    code: 'SGD',
    name: 'Singapore Dollar',
  },
};

export const REMINDER_TYPE = {
  EMAIL_ALL: 'email-all',
  EMAIL_UNRESPONDED: 'email-unresponded',
  SLACK: 'slack',
  TEAMS: 'teams',
  DEFAULT: 'default',
};

export const FEATURES = {
  SERVICE_ACCOUNTS: 'serviceAccounts',
  OUTBOUND_EMAIL: 'outboundEmail',
  OUTBOUND_SLACK: 'outboundSlack',
};

export const COLORS = {
  GREEN: '#44ce4b',
  RED: '#ff382d',
  YELLOW: '#ffc532',
};
