<ion-app mode="md">
  <ion-content *ngIf="authService.accountStatus === 'inactive'">
    <div class="flexed" style="height: 100%; justify-content: center; align-items: center">
      <ion-card style="width: 50%; padding: 20px; font-size: 18px">
        Your organization's account has been deactivated. If you believe this is a mistake or you would like to
        reactivate the account, contact your organization's administrator or reach out to support at
        <a href="mailto:support@everyspacehq.com">support@everyspacehq.com</a>
      </ion-card>
    </div>
  </ion-content>
  <ng-container *ngIf="authService.accountStatus !== 'inactive'">
    <ng-container *ngIf="!authService.isEmailVerified">
      <ion-content>
        <div class="flexed" style="height: 100%; justify-content: center; align-items: center; flex-direction: column">
          <ion-card style="max-width: 560px; padding: 20px; font-size: 18px; text-align: center">
            <ion-icon name="mail-unread-outline" style="font-size: 4.2rem; opacity: 0.7; margin-top: 20px"></ion-icon>
            <h1>Check your email</h1>
            We just sent a link to confirm your email address. Reach out to
            <a href="mailto:support@everyspacehq.com">support@everyspacehq.com</a> with any questions.
            <div style="margin-top: 20px">
              <ion-button (click)="returnToLogin()" fill="clear">Return to login page</ion-button><br />
              <ion-button (click)="resendVerificationEmail()" fill="clear">Resend verification email</ion-button>
            </div>
          </ion-card>
        </div>
      </ion-content>
    </ng-container>

    <ng-container *ngIf="authService.isEmailVerified">
      <ion-header [hidden]="hideNavBar">
        <ion-toolbar>
          <div class="nav-header">
            <div class="flexed">
              <div >
                <a [routerLink]="logoLink" routerDirection="root" class="hidden-md">
                  <img src="{{ logo || environment.assetsBaseUrl + '/imgs/logo-dark@2x.png' }}" class="logo" />
                </a>
                <a [routerLink]="logoLink" routerDirection="root" class="shown-mobile">
                  <img src="{{ logo || environment.assetsBaseUrl + '/imgs/logo-dark@2x.png' }}" class="logo" />
                </a>
              </div>
              <div *ngIf="authService.user" class="hidden-mobile">
                <a
                  *ngFor="let p of appPages; let i = index"
                  class="nav-link"
                  routerDirection="root"
                  [routerLink]="[p.url]"
                  routerLinkActive="selected"
                  #rla="routerLinkActive"
                >
                  <ion-icon [name]="rla.isActive ? p.icon : p.icon + '-outline'" class="nav-icon"></ion-icon>
                  <span class="nav-title">{{ p.title }}</span>
                  <ion-icon
                    *ngIf="p.private"
                    name="lock-closed-outline"
                    class="padded-icon-left hidden-xl"
                    ngxTippy
                    [tippyProps]="{
                      arrow: false,
                      placement: 'bottom'
                    }"
                    data-tippy-content="Only visible to admins"
                  ></ion-icon>
                </a>
              </div>
            </div>
            <div>
              <ion-button
                shape="round"
                [routerLink]="'/upgrade'"
                routerDirection="root"
                class="btn-small"
                style="margin-right: 8px"
                [color]="trialExpired ? 'danger' : 'primary'"
                [fill]="trialExpired ? 'solid' : 'outline'"
                *ngIf="trialStatus && authService.isCompanyAdmin"
                ngxTippy
                [tippyProps]="{
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Click to upgrade"
              >
                <ion-icon name="arrow-up-circle" class="padded-icon"></ion-icon>
                {{ trialStatus }}
              </ion-button>
              <ion-button
                shape="round"
                (click)="openNotifications()"
                class="icon-button"
                fill="clear"
                detail="false"
                *ngIf="authService.user"
                ngxTippy
                [tippyProps]="{
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Notifications"
              >
                <ion-icon [name]="unreadNotifications ? 'notifications' : 'notifications-outline'"></ion-icon>
                <span class="notifications-bubble" *ngIf="unreadNotifications">{{ unreadNotifications }}</span>
              </ion-button>
              <ion-button
                class="btn-small icon-button add-button"
                shape="round"
                (click)="showCreateOptions()"
                *ngIf="authService.user"
                id="global-create-btn"
              >
                <ion-icon name="add"></ion-icon>
              </ion-button>
              <ion-button fill="clear" (click)="loginOrSignup()" *ngIf="!authService.user">
                Log in or sign up
              </ion-button>
              <ion-button
                shape="round"
                fill="clear"
                (click)="openProfile()"
                *ngIf="authService.user"
                class="hidden-mobile"
              >
                <app-avatar
                  size="36"
                  [style.width]="'36px'"
                  [style.height]="'36px'"
                  [name]="authService.user.displayName"
                  [image]="authService.userPhoto"
                >
                </app-avatar>
                <span class="hidden-xl text-with-left-icon" style="font-size: 0.85rem">{{
                  authService.user.displayName
                }}</span>
              </ion-button>
            </div>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-content>
      <ion-footer [hidden]="hideNavBar || !authService.user" class="shown-mobile">
        <ion-toolbar *ngIf="authService.user">
          <div class="nav-header">
            <a
              *ngFor="let p of appPages; let i = index"
              class="nav-link"
              routerDirection="root"
              [routerLink]="[p.url]"
              routerLinkActive="selected"
              #rla="routerLinkActive"
            >
              <ion-icon [name]="rla.isActive ? p.icon : p.icon + '-outline'" class="nav-icon"></ion-icon>
              <span class="nav-title">{{ p.title }}</span>
              <ion-icon
                *ngIf="p.private"
                name="lock-closed-outline"
                class="padded-icon-left hidden-xl"
                ngxTippy
                [tippyProps]="{
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Only visible to admins"
              ></ion-icon>
            </a>
            <a
              class="nav-link"
              routerDirection="root"
              [routerLink]="'/profile'"
              routerLinkActive="selected"
              #rla="routerLinkActive"
            >
              <div class="profile-photo">
                <app-avatar [size]="36" [name]="authService.user.displayName" [image]="authService.userPhoto">
                </app-avatar>
              </div>
            </a>
          </div>
        </ion-toolbar>
      </ion-footer>
    </ng-container>
  </ng-container>
</ion-app>
