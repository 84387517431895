import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { CommunityMembershipRulesComponent } from './community-membership-rules.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [CommunityMembershipRulesComponent],
  exports: [CommunityMembershipRulesComponent],
})
export class CommunityMembershipRulesModule {}
