import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';

import { collections, MAX_FILE_SIZE_MB, UPLOAD_STATUS, UPLOAD_TYPE } from 'src/app/constants';

import { Upload } from '../models';

import { AuthService } from './auth.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private afst: AngularFireStorage,
    private authService: AuthService,
    private afs: AngularFirestore,
    private usersService: UsersService
  ) {}

  // Handle image uploads for the angular-editor wysiwyg.
  uploadWysiwygFile(file: File) {
    const upload = new Upload(
      new File([file], this.afs.createId(), {
        type: file.type,
        lastModified: file.lastModified,
      })
    );
    const finishedUpload$ = from(this.uploadFileAsPromise(upload, `wysiwyg_images`));

    return finishedUpload$.map((finishedUpload) => {
      return new HttpResponse({
        body: {
          imageUrl: finishedUpload.url,
        },
      });
    });
  }

  // return a promise which upload file & get download URL
  async uploadFileAsPromise(upload: Upload, basePath: string): Promise<Upload> {
    return this.afst.storage
      .ref()
      .child(`${this.authService.tenantId}`)
      .child(`${basePath}`)
      .child(`${upload.file.name}`)
      .put(upload.file, { customMetadata: upload.metadata || {} })
      .then((snapshot) => {
        // upload success
        const bucket = snapshot.ref.bucket;
        upload.path = snapshot.ref.fullPath;

        const encodedPath = encodeURIComponent(`${upload.path}`);
        upload.url = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodedPath}?alt=media`;
        upload.name = upload.file.name;

        return upload;
        // return snapshot.ref.getDownloadURL().then(downloadURL => {
        //     const res = new Upload(file);
        //     res.url = downloadURL;
        //     res.path = snapshot.ref.fullPath

        //     console.log('GOT RES: ', res);
        //     return res;
        // });
      });
  }

  async upload(uploads: Upload[], basePath: string = 'plan_headers') {
    return Promise.all(
      uploads.map((item) => {
        return this.uploadFileAsPromise(item, basePath);
      })
    );
  }

  async uploadProfilePicture(userId: string, file: File) {
    if (file.size / 1000 / 1000 > MAX_FILE_SIZE_MB) {
      throw new Error('Files should not be larger than 5mb; try something smaller.');
    }

    const currentUpload = new Upload(file);

    currentUpload.metadata = {
      uploadId: this.afs.createId(),
      tenantId: this.authService.tenantId,
      createdBy: userId,
      filename: file.name,
      status: UPLOAD_STATUS.NONE,
      type: UPLOAD_TYPE.PROFILE_PIC,
      objectId: userId,
    };

    await this.upload([currentUpload], `profiles/${userId}`);
    await this.usersService.updateUser(userId, {
      photo: currentUpload.url,
      photoPath: currentUpload.path,
    });

    this.authService.profile.photo = currentUpload.url;
    this.authService.profile.photoPath = currentUpload.path;
  }
}
