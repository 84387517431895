import { Component } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';

import { AuthService } from 'src/services/auth.service';

import { FeedbackPage } from '../../feedback/feedback.page';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.page.html',
  styleUrls: ['./profile-popover.page.scss'],
})
export class ProfilePopoverPage {
  name: string;
  email: string;
  isBusiness = false;

  constructor(
    public popoverCtrl: PopoverController,
    public authService: AuthService,
    private modalCtrl: ModalController
  ) {
    const user = this.authService.user;
    this.name = user.displayName;
    this.email = user.email;

    this.isBusiness = true;
    user.providerData.map((val) => {
      if (val.providerId === 'phone') {
        this.isBusiness = false;
        return;
      }
    });
  }

  async sendFeedback() {
    this.popoverCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: FeedbackPage,
    });
    return await modal.present();
  }

  openHelpdesk() {
    window.open('https://helpdesk.everyspacehq.com/', '_blank');
  }

  logout() {
    this.authService.logout();
    this.popoverCtrl.dismiss();
  }
}
