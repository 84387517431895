const rootDomain = 'everyspacehq.com';
const publicDomain = `app.${rootDomain}`;
const internalDomain = `everyspace.${rootDomain}`;
const assetsBaseUrl = '/assets';

export const environment = {
  production: true,
  environment: 'prd',
  rootDomain,
  host: publicDomain,
  baseUrl: `https://${publicDomain}`,
  internalBaseUrl: `https://${internalDomain}`,
  assetsBaseUrl,
  firebase: {
    apiKey: 'AIzaSyAfEYDvOmQnwrUFhxsU0fKzsD6shSpyVWs',
    authDomain: 'everyspace-prd.firebaseapp.com',
    projectId: 'everyspace-prd',
    storageBucket: 'everyspace-prd.appspot.com',
    messagingSenderId: '704106218017',
    appId: '1:704106218017:web:645eadfeda183b47a9d960',
  },
  googleClientId: '704106218017-4b9rf0f5tmn4q73up56o85787kev1uq0.apps.googleusercontent.com',
  googleAnalyticsTrackingId: null,
  useEmulators: false,
  // NOTE: Slack app to be transferred to newco slack workspace.
  slack: {
    oauthURL: 'https://slack.com/oauth/v2/authorize',
    clientID: '6356178738166.2294409785011',
    scopes: 'incoming-webhook,channels:read,groups:read,users:read,users:read.email,chat:write,chat:write.customize',
    userScopes: 'channels:write,groups:write',
    requiredScopes: [
      'incoming-webhook',
      'channels:read',
      'groups:read',
      'users:read',
      'users:read.email',
      'chat:write',
      'chat:write.customize',
    ],
    requiredUserScopes: ['channels:write', 'groups:write'],
  },
  // NOTE: Zoom account to be transferred to newco.
  zoom: {
    clientID: 'KeHVeAUATqGoqNF2NGTzBw',
  },
  loomApiKey: null,
  mixPanelToken: null,
  sentryDsn: null,
  // NOTE: For newco - re-enable twilio if a new account is created and configured on the backend.
  twilioEnabled: false,
};
