import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { collections, routes } from 'src/app/constants';
import { ListRequest, ListResponse, Plan, RecurringEvent } from 'src/models';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

const SHORT_GUEST_ID_MAP = 'shortGuestIdMap';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private apiService: ApiService, private authService: AuthService, private afs: AngularFirestore) {}

  private baseEventsUrl(eventId?: string) {
    let url = ``;

    if (!this.authService.user || this.authService.tenantId == null) {
      url += '/public-events';
    } else {
      url += '/events';
    }

    if (eventId) {
      url += `/${eventId}`;
    }

    return url;
  }

  listForCommunity(communityId: string, options: ListRequest = {}): Promise<ListResponse<Partial<Plan>>> {
    return this.apiService.postPromise(`/communities/${communityId}/events/list`, options);
  }

  listRecurringForCommunity(
    communityId: string,
    options: ListRequest = {}
  ): Promise<ListResponse<Partial<RecurringEvent>>> {
    return this.apiService.postPromise(`/communities/${communityId}/recurring-events/list`, options);
  }

  getRecurringEvent(recurringEventId: string): Promise<Partial<RecurringEvent>> {
    return this.apiService.getPromise(`/recurring-events/${recurringEventId}`);
  }

  getEventById(eventId: string): Promise<any> {
    return this.apiService.getPromise(this.baseEventsUrl(eventId));
  }

  getFirestoreEventDocById(eventId: string) {
    return this.afs.doc<Plan>(`${collections.PLANS}/${eventId}`).get().toPromise();
  }

  async getGuestForEvent(eventId: string, shortGuestId?: string): Promise<any> {
    let url = this.baseEventsUrl(eventId);

    if (this.authService.user) {
      url += '/me/guest';
    } else if (shortGuestId) {
      url += `/guests/${shortGuestId}`;
    } else {
      return undefined;
    }

    return this.apiService.getPromise(url);
  }

  getEventLink(eventId: string) {
    // const subdomain = this.authService.subdomain ? `${this.authService.subdomain}.` : '';
    // const url = new URL(environment.baseUrl);
    // url.host = `${subdomain}${url.host}`;

    // We need to handle custom subdomains as well as custom domains
    // Could be the b2c everyspace site at app.everyspacehq.com
    // Could be {client}.everyspacehq.com
    // Or it could be events.client.com or mycustomdomain.com
    // The correct way to do this is to use the company-domain customDomain property
    // For now let's just use the URL of the browser
    const origin = window.location.origin;

    return `${origin}/${routes.EVENTS}/${eventId}`;
  }

  getShortGuestIdMap() {
    return JSON.parse(window.sessionStorage.getItem(SHORT_GUEST_ID_MAP) ?? '{}');
  }

  setShortGuestIdInSessionStorage(eventId: string, shortGuestId: string) {
    const shortGuestIdMap = this.getShortGuestIdMap();

    shortGuestIdMap[eventId] = shortGuestId;

    window.sessionStorage.setItem(SHORT_GUEST_ID_MAP, JSON.stringify(shortGuestIdMap));
  }

  getShortGuestIdInSessionStorage(eventId: string) {
    const shortGuestIdMap = this.getShortGuestIdMap();

    return shortGuestIdMap[eventId];
  }
}
