import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

export interface ActionDropdownType {
  label: string;
  icon?: string;
  selected?: boolean;
  metadata?: any;
  id?: string;
}

@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent {
  options: ActionDropdownType[] = [];
  checkbox: boolean;
  multiCheckbox: boolean;
  callback: (index: number, label: string, id?: string) => {};
  onCloseCallback: () => {};

  constructor(navParmas: NavParams) {
    this.options = navParmas.get('options');
    this.checkbox = navParmas.get('checkbox');

    if (this.checkbox) {
      this.options.map((opt) => {
        opt.icon = opt.selected ? 'checkmark-circle' : 'ellipse-outline';
      });
    }
  }

  didClick(index: number, label: string, id?: string) {
    if (this.checkbox) {
      const option = this.options[index];

      option.selected = !option.selected;
      option.icon = option.selected ? 'checkmark-circle' : 'ellipse-outline';

      this.options.splice(index, 1, option);
    }

    this.callback(index, label, id);
  }

  onClose() {
    this.onCloseCallback();
  }
}
