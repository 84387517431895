/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import firebase from 'firebase/app';

import { USER_STATUS_ACTIVE, USER_STATUS_INACTIVE, PLAN_STATUS, DISCOVERABILITY_SETTINGS } from 'src/app/constants';

export interface Metadata {
  creatorName: string;
  creatorPhone: string;
}

export class GuestRequest {
  title: string;
  required: boolean;
  value?: string;
  type: string;
  public?: boolean;
}

type DiscoverabilitySettings =
  | typeof DISCOVERABILITY_SETTINGS.NONE
  | typeof DISCOVERABILITY_SETTINGS.ADMIN
  | typeof DISCOVERABILITY_SETTINGS.ALL;

export interface PlanHost {
  name: string;
  image: string;
  type: string;
  role: string;
  category: string;
  objectId: string;
  tenantId: string | null;
  slackEnabled: boolean | null;
  slackChannelName: string | null;
  slackChannelId?: string;
  teamsEnabled: boolean | null;
  adminEmails?: string[];
  permissions?: {
    canMakeDiscoverable?: DiscoverabilitySettings;
  };
  defaultSettingsEventId?: string;
}

export interface RecurringEvent {
  id: string;
  communityId: string;
  title?: string;
  description?: string;
  photo?: string;
  status: string;
  rrule: string;
  createdAt: string;
  nextEventAt?: string;
  eventTemplate: Plan;
  guestsTemplate: Guest[];
}

export interface Plan {
  recurringEventId?: string;
  rrule?: string;
  photo?: string;
  photoPath?: string;
  metadata: Metadata;
  title: string;
  location: string;
  description: string;
  date: firebase.firestore.Timestamp;
  dateUnix: number;
  endDate?: firebase.firestore.Timestamp;
  endDateUnix?: number;
  utcOffset: number;
  timezone?: string;
  id?: string; // TODO: consolidate these two
  objectId?: string;
  createdBy: string;
  createdAt: any;
  updatedAt?: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  createdAtUnix: number;
  canceled?: boolean;
  guestRequests?: GuestRequest[];
  deviceInfo?: any;
  dateStatus?: string;
  dateOptions?: any[];
  planningPreferences?: any;
  venueStatus?: string;
  isVirtual?: boolean;
  isDiscoverable?: boolean;
  isPublic?: boolean;
  organizerId?: string;
  organizerName?: string;
  organizerCategory?: string;
  tenantId?: string;
  category?: string;
  videoconference?: any;
  guestListShown?: string;
  waitlistEnabled?: boolean;
  maxGuests?: number;
  attendingCount?: number;
  reminders?: any[];
  survey?: any;
  hosts?: PlanHost[];
  sharePlanToSlack?: boolean;
  sharePlanToTeams?: boolean;
  externalSource?: string;
  externalCalendarId?: string;
  externalCalendarUserId?: string;
  slackChannels?: any[];
  teamsChannels?: any[];
  sendUpdates?: string;
  status?: typeof PLAN_STATUS.DRAFT | typeof PLAN_STATUS.PUBLISHED;
  attendeePreview?: { name: string; photo: string }[];
  useAsDefault?: boolean;
  recordings?: string[];
}

export interface Guest {
  objectId?: string;
  planId: string;
  date: any;
  dateUnix: number;
  endDate?: any;
  endDateUnix?: number;
  phoneFormatted?: string;
  phoneNumber?: string;
  email?: string;
  rsvp: string;
  note?: string;
  chatNotificationPreference: string;
  newGuestNotificationEnabled?: boolean;
  seenAt?: any;
  sid: string;
  photo?: string;
  id?: string;
  userId?: string;
  name?: string;
  tenantId?: string;
  isHost: boolean;
  chatReadDate?: any;
  chatReadId?: string;
  isSelected?: boolean;
  deviceToken?: string;
  deviceInfo?: object;
  metadata?: any;
  createdBy: string;
  createdAt: any;
  updatedAt: firebase.firestore.Timestamp;
  guestRequests?: GuestRequest[];
  responses?: GuestRequest[];
  dateOptions?: any[];
  createdDeviceInfo: any;
  checkedIn: boolean;
  checkedInAt: firebase.firestore.FieldValue;
  checkedInBy?: string;
  customInviteMessage?: string;
  addToCalOnCreate?: boolean;
  _plan?: any;
  type?: string;
}

export interface Profile {
  deleted?: boolean;
  status?: typeof USER_STATUS_ACTIVE | typeof USER_STATUS_INACTIVE;
  deviceToken: string;
  photo?: string;
  photoPath?: string;
  phoneNumber?: string;
  email?: string;
  createdAt?: Date;
  updatedAt?: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  updatedBy?: string;
  lastSeenAt?: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  name?: string;
  objectId?: string;
  zoomEnabled?: boolean;
  googleCalendarEnabled?: boolean;
  pronoun?: string;
  description?: string;
  customFields?: any[];
  tenantId?: string;
  additionalIdpFields?: Record<string, unknown>;
  id?: string;
  title?: string;
  organization?: string;
  division?: string;
  costCenter?: string;
  employeeNumber?: string;
  department?: string;
  streetAddress?: string;
  country?: string;
  region?: string;
  locality?: string;
  postalCode?: string;
  slackId?: string;
  isExternal?: boolean;
  widgetVerificationHash?: string;
  communityMembers?: {
    communityId: string;
  }[];
}

export interface Comment {
  objectId?: string;
  createdAt: firebase.firestore.Timestamp;
  createdBy?: string;
  creatorPhoto?: string;
  userId?: string;
  name: string;
  planId?: string;
  guestId?: string;
  postId?: string;
  message: string;
  attachmentType: string;
  source?: string;
  guestPhotoURL?: string;
  attachments?: any;
  attachmentURL?: any;
  metadata?: any;
  replyingToComment?: any;
  topLevelId?: string;
  replyingToId?: string;
  tenantId?: string;
  isPublic?: boolean;
  reactionCount?: Record<string, number>;
}

export interface UserEngagement {
  score: string;
  user_id: string;
}

export interface AvgEngagementScore {
  avg_30_days: string;
  community_id: string;
  date: string;
}

export class Upload {
  $key: string;
  file: File;
  path: string;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();
  metadata?: {
    uploadId: string;
    tenantId: string;
    createdBy: string;
    filename: string;
    status: string;
    type: string;
    objectId: string;
    muteWelcomeEmail?: string; // firebase storage only wants strings
  };

  constructor(file: File) {
    this.file = file;
  }
}

export class CatalogExperience {
  id: string;
  title: string;
  description: string;
  tagline: string;
  cost: {
    min?: number;
    max?: number;
    summary?: string;
  };
  partySize: {
    min?: number;
    max?: number;
    summary?: string;
  };
  duration: {
    min?: number;
    max?: number;
    summary?: string;
  };
  location: {
    summary?: string;
  };
  medium: string;
  type: string;
  images: string[];
  category: string;
  createdAt: firebase.firestore.FieldValue;
  createdBy: string;
  tenantId?: string;
  vendorName: string;
  vendorEmail: string;
  priority: number;
}

export const AUTH_PROVIDER_PASSWORD = 'password';
export const AUTH_PROVIDER_EMAIL_LINK = 'emailLink';
export const AUTH_PROVIDER_GSUITE = 'gsuite';
export const AUTH_PROVIDER_OKTA_OIDC = 'okta-oidc';
export const AUTH_PROVIDER_OKTA_SAML = 'okta-saml';
export const AUTH_PROVIDER_PHONE = 'phone';
export const AUTH_PROVIDER_MICROSOFT = 'microsoft.com';

export type AuthProvider =
  | typeof AUTH_PROVIDER_PASSWORD
  | typeof AUTH_PROVIDER_GSUITE
  | typeof AUTH_PROVIDER_OKTA_OIDC
  | typeof AUTH_PROVIDER_OKTA_SAML
  | typeof AUTH_PROVIDER_PHONE
  | typeof AUTH_PROVIDER_MICROSOFT;

export class Tenant {
  subdomain: string;
  slackTeamId?: string;
  slackTeamName?: string;
  slackTeamUrl?: string;
  workos?: any;
  id?: string;
  adminEmails: string[];
  name: string;
  isPublic: boolean;
  logo: string;
  primaryColor: string;
  provider: string;
  authProviders?: AuthProvider[];
  email?: string;
  phone?: string;
  allowedEmailDomains: string[];
  customMarkup?: string;
  permissions: {
    canCreatePlans?: boolean;
    canCreateOrganizers?: boolean;
    canCreatePosts?: boolean;
  };
  users: {
    phoneNumberRequired?: boolean;
    customProfileFields?: any[];
  };
  defaultFieldSettings?: {
    [id: string]: {
      public?: boolean;
    };
  };
  homePageFilters?: string[];
  accountStatus?: string;
  integrations?: any;
  defaultCommunityId?: string;
  trialEndDate: any;
  isInternal: boolean;
  workingHours: {
    enabled: boolean;
    intervals: any[];
  };
  billing?: {
    restrictedFeatures?: string[];
  };
}

export class Organizer {
  name: string;
  tagline: string;
  description: string;
  category?: string;
  createdBy: string;
  updatedBy?: string;
  objectId?: string;
  image: string;
  tenantId?: string;
  createdAt: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  adminEmails: string[];
  admins: string[];
  calendarUrl: string;
  email: string;
  slackChannelName?: string;
  slackChannelId?: string;
  slackChannelIsPrivate?: boolean;
  slackChannelIsShared?: boolean;
  followerCount: number;
  isPublic: boolean;
  isDiscoverable: boolean;
  showMemberList: string;
  newMemberNotification: string;
  newMemberNotificationOptOutList: string[];
  newMemberWelcomeEmailTemplate?: string;
  eventbriteFeedUrl?: string;
  eventbriteId?: string;
  externalCalendar?: {
    id?: string;
    sync?: string;
    source?: 'microsoft.com' | 'google.com';
    name?: string;
    userId?: string;
  };
  slackEnabled?: boolean;
  teamsEnabled?: boolean;
  syncSlackChannel?: boolean;
  teamsWebhookUrl?: string;
  memberSourceType?: string;
  permissions?: {
    canCreateEvents?: boolean;
    canCreatePosts?: boolean;
    canMakeDiscoverable?: DiscoverabilitySettings;
  };
  removed?: boolean;
  customFields?: any[];
  id?: string;
  defaultSettingsEventId?: string;
  slackTeamId?: string;
  slackTeamName?: string;
  tags?: Tag[];
  currency?: string;
}

export interface OrganizerFollow {
  organizerId: string;
  customFields?: Record<string, unknown>;
  following?: boolean;
  userId?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  user?: Profile;
  createdAt?: Date;
  tags?: UserTag[];
  notes?: string;
}

export interface CommunityMember {
  communityId: string;
  userId: string;
  following: boolean;
  customFields?: Record<string, unknown>;
  tenantId: string | null;
  user?: Profile;
  isExternal?: boolean;
}

export interface PostAttachment {
  title: string;
  type: string;
  width?: number;
  height?: number;
  id?: string;
  embedUrl?: string;
  providerUrl?: string;
  sharedUrl: string;
}

export class Post {
  title: string;
  description: string;
  type: string;
  image?: string;
  createdAt: firebase.firestore.Timestamp;
  createdBy: string;
  creatorName?: string;
  creatorPhoto?: string;
  updatedAt?: firebase.firestore.Timestamp;
  updatedBy?: string;
  isDiscoverable: boolean;
  isPublic: boolean;
  shareSlackOnCreate?: boolean;
  shareTeamsOnCreate?: boolean;
  shareEmailOnCreate?: boolean;
  commentsEnabled: boolean;
  reactionsEnabled: boolean;
  organizerName: string;
  organizerId: string;
  organizerCategory: string;
  pinned: boolean;
  objectId: string;
  tenantId: string;
  attachments?: PostAttachment[];
  surveyQuestions?: GuestRequest[];
  status?: string;
  videoUrl?: string;
  videoIsYoutube?: boolean;
  slackChannels: any[];
  isSurveyAnonymous: boolean;
}

export interface UserTag {
  id: string;
  name: string;
  communityId: string;
  rule: any;
}

export interface Tag {
  id: string;
  name: string;
}

export class SurveyResponse {
  userId: string;
  responses: GuestRequest[];
  createdAt: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  postId: string;
  tenantId: string | null;
  userName: string;
  userPhoto: string;
  userEmail: string;
}

export class Template {
  id: string;
  name: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  chunks?: any;
  design?: any;
  html: string;
  communityId?: string;
  tenantId: string;
  isDiscoverable: boolean;
  image?: string;
  createdBy?: string;
}

export class UserNotification {
  createdAt: firebase.firestore.Timestamp;
  userId: string;
  tenantId: string;
  read: boolean;
  readAt?: firebase.firestore.Timestamp;
  image?: string;
  type: string;
  data?: any;
  objectId?: string;
}

export class UnsplashPhoto {
  id: string;
  alt_description: string;
  blur_hash: string;
  categories: string[];
  color: string;
  crearedAt: string;
  current_user_collections: any[];
  description: string;
  height: number;
  liked_by_user: boolean;
  likes: number;
  links: any;
  promoted_at: string;
  sponsorship: any;
  updated_at: string;
  urls: any;
  user: any;
  width: number;
}

export interface ListQueryResult {
  rows: any[];
  count: number;
}

export interface GoogleCalendarListResponse {
  kind: string;
  etag: string;
  nextPageToken: string;
  nextSyncToken: string;
  items: GoogleCalendar[];
}

export interface GoogleCalendar {
  kind: string;
  etag: string;
  id: string;
  summary: string;
  description: string;
  location: string;
  timeZone: string;
  summaryOverride: string;
  colorId: string;
  backgroundColor: string;
  foregroundColor: string;
  hidden: boolean;
  selected: boolean;
  accessRole: 'freeBusyReader' | 'reader' | 'writer' | 'owner';
  defaultReminders: [
    {
      method: string;
      minutes: string;
    }
  ];
  notificationSettings: {
    notifications: [
      {
        type: string;
        method: string;
      }
    ];
  };
  primary: boolean;
  deleted: boolean;
  conferenceProperties: {
    allowedConferenceSolutionTypes: [string];
  };
}

export interface OutlookCalendarListResponse {
  value: OutlookCalendar[];
}

export interface OutlookCalendar {
  canEdit: boolean;
  id: string;
  name: string;
  isDefaultCalendar: boolean;
}

export interface ExternalCalendar {
  name: string;
  canEdit: boolean;
  source: 'google.com' | 'microsoft.com';
  isDefaultCalendar: boolean;
  id: string;
  userId?: string;
}

export interface Integration {
  imageSrc: string;
  title: string;
  bullets: any[];
  connect: string;
  html: string;
}

export interface IntegrationCatalog {
  imageSrc: string;
  url: string;
  key: string;
  name: string;
  title: string;
  bullets: string[];
  html: string;
  connect: string;
}

export interface WhereClause {
  field: string;
  operator: firebase.firestore.WhereFilterOp;
  value: any;
}

export interface ListRequest {
  where?: WhereClause[];
  order?: string[];
  select?: string[];
  limit?: number | null;
  descending?: boolean;
  nextCursor?: string;
  previousCursor?: string;
  [key: string]: any;
}

export interface PgWhereClause {
  field: string;
  operator: '==' | '!=' | '<' | '>' | '<=' | '>=' | 'like';
  value: any;
}

export interface PgListRequest {
  where?: PgWhereClause[];
  order?: string[];
  select?: string[];
  limit?: number | null;
  descending?: boolean;
  cursor?: string;
}

export interface ListResponse<T> {
  data: T[];
  totalCount: number;
  cursors: {
    next?: string;
    previous?: string;
    hasNext: boolean;
    hasPrevious: boolean;
  };
}

export interface Expense {
  id: string;
  title: string;
  communityId: string;
  eventId: string | null;
  userId: string | null;
  description: string | null;
  amount: number;
  date: string;
  externalId: string | null;
  imageUrl: string | null;
  currency: string;
}

export interface Budget {
  id: string;
  communityId: string;
  amount: number;
  spend: number;
  remaining: number;
  year: number;
  currency: string;
}

export interface Outbound {
  id: string;
  tenantId: string;
  deliveryMethod: String;
  runAt?: Date;
  subject?: string;
  blocks?: any[];
  recipients?: any[];
  communityId?: string;
  sendAs?: any;
  sendToSlackChannel: boolean;
  status: string;
}

export interface PostReaction {
  id: string;
  type: string;
}

export interface ServiceAccount {
  id: string;
  name: string;
  status: string;
  tenantId: string;
  createdAt: Date;
  crreatedBy: string;
  updatedAt: Date;
  updatedBy: string;
  token?: string;
}

export interface SlackSurvey {
  buttonLabel: string;
  type: 'nps' | 'csat';
  postSubmissionMessage: string;
}
