import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import firebase from 'firebase/app';

import { USER_NOTIFICATION_TYPES, routes, collections } from 'src/app/constants';
import { UserNotification } from 'src/models';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  notifications: UserNotification[] = [];
  unreadCount = 0;

  constructor(
    private afs: AngularFirestore,
    private navController: NavController,
    private popoverCtrl: PopoverController,
    navParams: NavParams
  ) {
    this.notifications = navParams.get('notifications');
    this.unreadCount = navParams.get('unreadCount');
  }

  selectNotification(notification: UserNotification) {
    switch (notification.type) {
      case USER_NOTIFICATION_TYPES.NEW_INVITE:
      case USER_NOTIFICATION_TYPES.EVENT_CHAT_RECEIVED:
      case USER_NOTIFICATION_TYPES.EVENT_UPDATED: {
        this.navController.navigateRoot(`${routes.EVENTS}/${notification.data.planId}`);
        break;
      }
      case USER_NOTIFICATION_TYPES.GUEST_RSVP_UPDATED: {
        this.navController.navigateRoot(`${routes.EVENT_ADMIN}/${notification.data.planId}/guests`);
        break;
      }
      case USER_NOTIFICATION_TYPES.GUEST_SURVEY_UPDATED: {
        this.navController.navigateRoot(`${routes.EVENT_ADMIN}/${notification.data.planId}/surveys?form=survey`);
        break;
      }
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_FOLLOW:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UNFOLLOW:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_SUCCEEDED:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_FAILED: {
        this.navController.navigateRoot(`${routes.COMMUNITY_ADMIN}/${notification.data.organizerId}/members`);
        break;
      }
      case USER_NOTIFICATION_TYPES.SURVEY_RESPONSE_UPDATED: {
        this.navController.navigateRoot(`${routes.POSTS}/${notification.data.postId}`);
        break;
      }
      default:
        break;
    }

    this.markRead(notification);
    this.popoverCtrl.dismiss();
  }

  markRead(notification?: UserNotification) {
    const batch = this.afs.firestore.batch();

    if (notification) {
      notification.read = true;
      notification.readAt = firebase.firestore.Timestamp.fromDate(new Date());
      batch.update(this.afs.collection(collections.NOTIFICATIONS).doc(notification.objectId).ref, {
        read: true,
        readAt: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    } else {
      this.notifications.forEach((n) => {
        n.read = true;
        n.readAt = firebase.firestore.Timestamp.fromDate(new Date());
        batch.update(this.afs.collection(collections.NOTIFICATIONS).doc(n.objectId).ref, {
          read: true,
          readAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      });
    }

    batch.commit();
  }

  getIcon(notification: UserNotification) {
    switch (notification.type) {
      case USER_NOTIFICATION_TYPES.NEW_INVITE:
      case USER_NOTIFICATION_TYPES.EVENT_CHAT_RECEIVED:
      case USER_NOTIFICATION_TYPES.EVENT_UPDATED:
      case USER_NOTIFICATION_TYPES.EVENT_CANCELED:
      case USER_NOTIFICATION_TYPES.GUEST_RSVP_UPDATED:
      case USER_NOTIFICATION_TYPES.GUEST_SURVEY_UPDATED:
        return 'calendar-outline';
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_FOLLOW:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UNFOLLOW:
        return 'people-circle-outline';
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_FAILED:
        return 'cloud-offline-outline';
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_SUCCEEDED:
        return 'cloud-done-outline';
      case USER_NOTIFICATION_TYPES.SURVEY_RESPONSE_UPDATED:
        return 'list-outline';
      default:
        return 'calendar-outline';
    }
  }
}
