import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RouterLinkDelegate } from '@ionic/angular';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
  interface Window {
    appConfig: {
      thirdPartyCookiesEnabled?: boolean;
      subdomain?: string;
      tenantId?: string;
      logo?: string;
      primaryColor?: string;
      authProviders?: string[];
      domains?: string[];
      homePageFilters?: string[];
      analyticsDisabled: boolean;
      phoneNumberRequirement: string;
      customProfileFields?: any[];
      integrations?: {
        slack: string;
        zoom: string;
        googleCalendar: string;
        outlook: string;
        teams: string;
        marketplace: string;
        workos: string;
      };
      useLegacyAuthDomain: boolean;
      workos?: {
        directoryId?: string;
      };
      defaultCommunityId?: string;
      defaultFieldSettings?: Record<string, { public?: boolean }>;
      permissions?: {
        canCreatePlans?: boolean;
        canCreatePosts?: boolean;
        canCreateOrganizers?: boolean;
        canViewPeople?: boolean;
      };
      type?: string;
      slackTeamId?: string;
      slackTeamName?: string;
      accountStatus?: string;
      emailSupport?: {
        enabled?: boolean;
        slackChannelId?: string;
      };
    };
  }
}

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.environment,
    enabled: environment.environment === 'prd',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://everyspace-stg.web.app', 'https://*.everyspacehq.com', 'https://everyspacehq.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}

// HACK: Fix for bug in ionic: https://github.com/ionic-team/ionic-framework/issues/24413
// Fix was copied from here: https://github.com/ionic-team/ionic-framework/pull/24440
RouterLinkDelegate.prototype.onClick = function (ev: MouseEvent) {
  if (!this.elementRef.nativeElement.closest('ion-button')) {
    // In case of CTRL+Click or Meta+Click,
    // ignore those to allow default browser actions.
    if (ev.button === 0 && (ev.ctrlKey || ev.metaKey)) {
      return;
    }
  }

  this.navCtrl.setDirection(this.routerDirection, undefined, undefined, this.routerAnimation);
  ev.preventDefault();
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
