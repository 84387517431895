import 'rxjs/add/operator/mergeMap';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as Sentry from '@sentry/angular';
import firebase from 'firebase/app';
import _ from 'lodash';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

import { FEATURES } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { Tenant, Profile } from 'src/models';

import { TenantsService } from './tenants.service';
import { UsersService } from './users.service';

const HAD_AUTH_ERROR_KEY = 'hadAuthError';
const WAS_MANUAL_LOGOUT = 'wasManualLogout';
const WAS_AUTO_SIGN_IN = 'wasAutoSignIn';

const CUSTOM_TOKEN_REQUEST_TYPE = 'customTokenRequest';
const CUSTOM_TOKEN_RESPONSE_TYPE = 'customTokenResponse';

const COOKIE_PREFIX = '__session';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  profile: Profile;
  user: firebase.User;
  tenant: Tenant;
  credential: firebase.auth.UserCredential;
  idTokenResult: firebase.auth.IdTokenResult;
  subdomain: string;
  tenantId: string;
  logo: string;
  primaryColor: string;
  authProviders: string[];
  allowedEmailDomains: string[];
  homePageFilters: any[];
  analyticsDisabled: boolean;
  isCompanyAdmin = false;
  isSuperAdmin = false;
  isReady = false;
  authReadyCallbacks = [];
  accountStatus = 'active';
  phoneNumberRequirement = 'none';
  customProfileFields = [];
  integrations: {
    googleCalendarEnabled: boolean;
    outlookEnabled: boolean;
    slackEnabled: boolean;
    teamsEnabled: boolean;
    workosEnabled: boolean;
    zoomEnabled: boolean;
    marketplaceEnabled: boolean;
  };
  workos?: {
    directoryId?: string;
  };
  defaultCommunityId?: string;
  defaultFieldSettings?: Record<string, { public?: boolean }>;
  permissions?: {
    canCreatePlans?: boolean;
    canCreatePosts?: boolean;
    canCreateOrganizers?: boolean;
    canViewPeople?: boolean;
  };
  slackTeamId?: string;
  slackTeamName?: string;
  isDemo: boolean;
  isEmailVerified: boolean = false;
  restrictedFeatures: string[] = [];
  useLegacyAuthDomain: boolean = false;

  constructor(
    private afa: AngularFireAuth,
    private tenantsService: TenantsService,
    private usersService: UsersService
  ) {
    const {
      subdomain = null,
      tenantId = null,
      logo = null,
      primaryColor = null,
      authProviders = [],
      analyticsDisabled = false,
      phoneNumberRequirement = null,
      customProfileFields = [],
      useLegacyAuthDomain = false,
    } = window.appConfig || {};

    this.subdomain = subdomain || 'app';
    this.tenantId = tenantId;
    this.logo = logo;
    this.primaryColor = primaryColor;
    this.authProviders = authProviders;
    this.isDemo = subdomain === 'piedpiper';
    this.analyticsDisabled = analyticsDisabled;
    this.phoneNumberRequirement = phoneNumberRequirement;
    this.customProfileFields = customProfileFields;
    this.useLegacyAuthDomain = useLegacyAuthDomain;

    this.load();
  }

  async load() {
    try {
      // HACK: The credential observable below isn't throwing errors from auth providers. Call getRedirectResult manually
      // to trigger this so we can display an error and log it. Also note, firebase version 9 seems to have a bug where
      // the error isn't thrown here either so we can't upgrade until they fix that issue.
      // NOTE: This must run before getting the user from auth state or the error may not be thrown.
      await this.afa.getRedirectResult();

      const user = await this.afa.authState.pipe(take(1)).toPromise();

      this.user = user;

      const [idTokenResult, credential] = await Promise.all([
        this.afa.idTokenResult.pipe(take(1)).toPromise(),
        this.afa.credential.pipe(take(1)).toPromise(),
      ]);

      this.idTokenResult = idTokenResult;
      this.isEmailVerified = idTokenResult?.signInProvider === 'password' ? idTokenResult?.claims.email_verified : true;

      // On first page load after verifying email, the token might have the wrong value, so refresh it.
      if (this.user?.emailVerified && !this.isEmailVerified) {
        this.idTokenResult = await this.user.getIdTokenResult(true);
        this.isEmailVerified = true;
      }

      this.credential = credential;
      this.isSuperAdmin = idTokenResult?.claims?.isSuperAdmin;

      if (this.isEmailVerified) {
        await Promise.all([
          user ? this.loadCompany() : Promise.resolve(null),
          user ? this.loadProfile() : Promise.resolve(null),
          user ? this.usersService.setLastSeen() : Promise.resolve(null),
        ]);
      }

      document.cookie = `${COOKIE_PREFIX}=${this.userId}; domain=${document.location.hostname}; max-age=31536000; secure; path=/`;

      this.identifyUserForAnalytics();
      this.isReady = true;
      this.authReadyCallbacks.forEach((authReadyCallback) => authReadyCallback());
    } catch (error) {
      // If there was an error fetching the profile, log them out and set a flag
      // so the login page knows to show an error message.
      console.error('Error fetching profile', error);
      Sentry.captureException(error, {
        user: {
          id: this.user?.uid,
          tenantId: this.tenantId,
        },
      });

      this.setAuthError(error);
      this.logout();
    }
  }

  onAuthReady(authReadyCallback) {
    if (this.isReady) {
      authReadyCallback();
    } else {
      this.authReadyCallbacks.push(authReadyCallback);
    }
  }

  isLoggedIn() {
    return !!this.user;
  }

  get userPhoto() {
    return (this.profile && this.profile.photo) || (this.user && this.user.photoURL) || '';
  }

  get userId() {
    return this.idTokenResult?.claims?.databaseId ?? this.idTokenResult?.claims?.user_id;
  }

  async getProfileById(id: string, isExternal: boolean = false) {
    return this.usersService.getUser(id, isExternal) as any;
  }

  async loadProfile() {
    const profile = await this.usersService.getMe();

    this.profile = profile as any;

    return profile;
  }

  async loadCompany() {
    try {
      const tenant = await this.tenantsService.getTenant();

      this.tenant = tenant;
      this.isCompanyAdmin = Boolean(this.user) && Boolean(tenant?.adminEmails?.includes(this.user.email));
      this.customProfileFields = tenant.users?.customProfileFields ?? [];
      this.allowedEmailDomains = tenant.allowedEmailDomains;
      this.homePageFilters = tenant.homePageFilters;
      this.integrations = {
        slackEnabled: tenant.integrations.slack !== 'disabled',
        teamsEnabled: tenant.integrations.teams !== 'disabled',
        googleCalendarEnabled: tenant.integrations.googleCalendar !== 'disabled',
        outlookEnabled: tenant.integrations.outlook !== 'disabled',
        workosEnabled: tenant.integrations.workos !== 'disabled',
        zoomEnabled: tenant.integrations.zoom !== 'disabled',
        marketplaceEnabled: tenant.integrations.marketplace !== 'disabled',
      };
      this.workos = tenant.workos;
      this.defaultFieldSettings = tenant.defaultFieldSettings;
      this.permissions = tenant.permissions;
      this.defaultCommunityId = tenant.defaultCommunityId;
      this.slackTeamId = tenant.slackTeamId;
      this.slackTeamName = tenant.slackTeamName;

      if (tenant.accountStatus) {
        this.accountStatus = tenant.accountStatus;
      }

      // Some companies may have specially restricted features due to custom contract
      let restrictedFeatures = tenant.billing?.restrictedFeatures ?? [];

      if (tenant?.accountStatus === 'trial' && tenant.trialEndDate) {
        // Trial customers get access to everything but outbound email
        const end = moment(tenant.trialEndDate);
        const trialExpired = end.diff(moment()) < 0;

        if (trialExpired) {
          restrictedFeatures = _.values(FEATURES);
        } else {
          restrictedFeatures = [FEATURES.OUTBOUND_EMAIL];
        }
      } else {
        // If the tenant doesn't have a trial, they are a paid customer and get access to everything
        restrictedFeatures = [];
      }

      this.restrictedFeatures = restrictedFeatures;

      return tenant;
    } catch {
      this.isCompanyAdmin = false;

      // Not an admin of this group. We'll get a permission error, but we can safely ignore this error.
    }
  }

  async updateTenant(data: Partial<Tenant>): Promise<void> {
    await this.tenantsService.updateTenant(data);
    await this.loadCompany();
  }

  authGoogleCalendar(callback: (code: string) => void) {
    const googleOauth2Client = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleClientId,
      scope: 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
      ux_mode: 'popup',
      callback: (response) => {
        callback(response.code);
      },
    });

    googleOauth2Client.requestCode();
  }

  setAuthError(authError: any) {
    window.sessionStorage.setItem(
      HAD_AUTH_ERROR_KEY,
      JSON.stringify({ message: authError.message, code: authError.code })
    );
  }

  getAuthError() {
    const authErrorMessage = window.sessionStorage.getItem(HAD_AUTH_ERROR_KEY);

    window.sessionStorage.removeItem(HAD_AUTH_ERROR_KEY);

    return JSON.parse(authErrorMessage);
  }

  setWasManualLogoutFlag() {
    window.sessionStorage.setItem(WAS_MANUAL_LOGOUT, String(true));
  }

  getWasManualLogoutFlag() {
    const wasManualLogout = window.sessionStorage.getItem(WAS_MANUAL_LOGOUT) === String(true);

    window.sessionStorage.removeItem(WAS_MANUAL_LOGOUT);

    return wasManualLogout;
  }

  setAutoSigninFlag() {
    window.sessionStorage.setItem(WAS_AUTO_SIGN_IN, String(true));
  }

  getAutoSignInFlag() {
    const wasAutoSignIn = window.sessionStorage.getItem(WAS_AUTO_SIGN_IN) === String(true);

    window.sessionStorage.removeItem(WAS_AUTO_SIGN_IN);

    return wasAutoSignIn;
  }

  async logout() {
    await this.afa.signOut();

    document.cookie = `${COOKIE_PREFIX}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;

    // Prevent auto-login on a manual sign out.
    this.setWasManualLogoutFlag();

    window.location.reload();
  }

  identifyUserForAnalytics() {
    if (!this.user) {
      return;
    }

    const config: any = {
      app_id: 'pg8i5ox5',
      company: {
        company_id: this.tenantId,
        name: this.subdomain,
      },
    };

    if (!this.analyticsDisabled) {
      config.name = this.user.displayName;
      config.email = this.user.email;
      config.user_id = this.userId;
      config.is_tenant_admin = this.isCompanyAdmin;
    }

    (window as any).Intercom('boot', config);
  }

  isWorkosEnabled() {
    return this.workos?.directoryId && this.integrations?.workosEnabled;
  }

  sendCustomToken(customToken: string, origin: string) {
    function listener(event) {
      if (event.origin !== origin || event.data?.type !== CUSTOM_TOKEN_REQUEST_TYPE) {
        return;
      }

      // @ts-ignore
      event.source.postMessage({ type: CUSTOM_TOKEN_RESPONSE_TYPE, data: customToken }, event.origin);

      window.removeEventListener('message', listener, false);
    }

    window.addEventListener('message', listener, false);
  }

  getCustomToken(opener: Window) {
    return new Promise<string>((resolve) => {
      opener.postMessage({ type: CUSTOM_TOKEN_REQUEST_TYPE }, environment.internalBaseUrl);

      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== environment.internalBaseUrl || event.data?.type !== CUSTOM_TOKEN_RESPONSE_TYPE) {
            return;
          }

          resolve(event.data.data);
        },
        {
          once: true,
          capture: false,
        }
      );
    });
  }

  async refreshUser(user: firebase.User) {
    this.idTokenResult = await user.getIdTokenResult();
    this.isSuperAdmin = this.idTokenResult.claims?.isSuperAdmin;

    await this.loadProfile();
  }

  get isPrd() {
    return environment.environment === 'prd';
  }

  sendVerificationEmailForUser(user: firebase.User) {
    return user.sendEmailVerification({
      url: document.location.origin,
    });
  }
}
