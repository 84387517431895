import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() image: string;
  @Input() name: string;
  @Input() size = 120;

  constructor() {}

  getImgSource() {
    return (
      this.image ||
      `https://source.boringavatars.com/beam/${this.size}/${this.name}?colors=D5B7E0,E87474,EC894D,EAC423,C0DBAB`
    );
  }
}
