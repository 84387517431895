import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { GiphyModalModule } from '../giphy-modal/giphy-modal.module';

import { SlackBlockEditComponent } from './slack-block-edit/slack-block-edit.component';
import { SlackBlockPreviewComponent } from './slack-block-preview/slack-block-preview.component';
import { SlackBlocksComponent } from './slack-blocks.component';
import { SlackSurveyPreviewComponent } from './slack-survey-preview/slack-survey-preview.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AngularEditorModule, GiphyModalModule],
  declarations: [
    SlackBlocksComponent,
    SlackBlockEditComponent,
    SlackBlockPreviewComponent,
    SlackSurveyPreviewComponent,
  ],
  exports: [SlackBlocksComponent],
})
export class SlackBlocksModule {}
