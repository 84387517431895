import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

interface MessageOptions {
  duration?: number;
  position?: 'top' | 'bottom' | 'middle';
}

function errorToMessage(error: any) {
  if (typeof error === 'string') {
    return error;
  }

  return error?.error?.errors?.[0].title ?? error.message ?? 'Something went wrong. Please try again.';
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private toastCtrl: ToastController) {}

  async show(message: string, { duration = 3000, position = 'top' }: MessageOptions = {}) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position,
    });

    toast.present();
  }

  async showBottom(message: string, options: MessageOptions = {}) {
    await this.show(message, {
      ...options,
      position: 'bottom',
    });
  }

  async showError(error: any) {
    this.show(errorToMessage(error));
  }

  async showErrorBottom(error: any) {
    this.showBottom(errorToMessage(error));
  }
}
