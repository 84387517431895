<ion-list [ngStyle]="{ overflow: 'auto' }">
  <ion-item *ngFor="let opt of options; let i = index;" button="true" (click)="didClick(i, opt.label, opt.id)" id="{{opt.id}}">
    <ion-label>
      <ion-icon *ngIf="opt.icon" name="{{opt.icon}}" class="icon-padded"></ion-icon>
      {{opt.label}}
    </ion-label>
  </ion-item>
</ion-list>
<div [ngStyle]="{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }">
  <ion-button *ngIf="multiCheckbox" (click)="onClose()" [ngStyle]="{ width: '100px' }">Close</ion-button>
</div>
