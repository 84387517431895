import { Injectable } from '@angular/core';

/* eslint-disable max-len */

@Injectable()
export class StockPhotosService {
  photos = {
    'Health & Wellness': [
      'https://images.unsplash.com/photo-1487700160041-babef9c3cb55?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/reserve/YEc7WB6ASDydBTw6GDlF_antalya-beach-lulu.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/photo-1500904156668-758cff89dcff',
      'https://images.unsplash.com/photo-1554244933-d876deb6b2ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/photo-1560233026-ad254fa8da38?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
    ],
    Music: [
      'https://images.unsplash.com/photo-1458560871784-56d23406c091?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/photo-1483412033650-1015ddeb83d1',
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e',
      'https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/photo-1487180144351-b8472da7d491?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/photo-1484755560615-a4c64e778a6c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
      'https://images.unsplash.com/photo-1477233534935-f5e6fe7c1159',
      'https://images.unsplash.com/photo-1511735111819-9a3f7709049c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
    ],
    'Food & Drink': [
      'https://images.unsplash.com/photo-1495195134817-aeb325a55b65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
    ],
    Marketing: [
      'https://images.unsplash.com/uploads/141103282695035fa1380/95cdfeef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
    ],
  };

  constructor() {}

  getRandomPhotoForCategory(category: string) {
    const photos = this.photos[category];
    if (photos) {
      return photos[Math.floor(Math.random() * photos.length)];
    }
    return '';
  }

  getPlaceholderPhoto(category: string) {
    const photos = this.photos[category];
    if (photos) {
      // const i = photos.length % (index + 1);
      return photos[0];
    }
    return 'https://firebasestorage.googleapis.com/v0/b/everyspace-prd.appspot.com/o/assets%2Fplaceholder-light%402x.jpg?alt=media&token=102acd6a-6b8c-4815-8513-b563fdd54f4b';
  }
}
