import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { CommunityMembershipRulesModule } from 'src/app/components/community-membership-rules/community-membership-rules.module';

import { EditUserTagComponent } from './edit-user-tag.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, CommunityMembershipRulesModule],
  declarations: [EditUserTagComponent],
  exports: [EditUserTagComponent],
})
export class EditUserTagModule {}
