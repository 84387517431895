import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/auth';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Sentry from '@sentry/angular';
import { EmailEditorModule } from 'angular-email-editor';
import { ThemeService } from 'ng2-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { SharedDirectivesModule } from 'src/directives/share-directives.module';
import { environment } from 'src/environments/environment';
import { SharedPipesModule } from 'src/pipes/shared-pipes.module';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { EventsService } from 'src/services/events.service';
import { MessageService } from 'src/services/message.service';
import { PlansService } from 'src/services/plans.service';
import { StockPhotosService } from 'src/services/stock-photos.service';
import { TenantsService } from 'src/services/tenants.service';
import { UploadService } from 'src/services/upload.service';
import { UsersService } from 'src/services/users.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PasswordLinkPage } from './auth/password-link/password-link.page';
import { ActionDropdownComponent } from './components/action-dropdown/action-dropdown.component';
import { AvatarModule } from './components/avatar/avatar.module';
import { CommunityMembershipRulesModule } from './components/community-membership-rules/community-membership-rules.module';
import { ComposePage } from './components/compose/compose.page';
import { CustomFieldsModule } from './components/custom-fields/custom-fields.module';
import { ResponseInputModule } from './components/custom-fields/response-input/response-input.module';
import { DatetimePickerModule } from './components/datetime-picker/datetime-picker.module';
import { EditUserTagModule } from './components/edit-user-tag/edit-user-tag.module';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { MessagingModule } from './components/messaging/messaging.module';
import { SlackBlocksModule } from './components/slack-blocks/slack-blocks.module';
import { UploadPage } from './components/upload/upload.page';
import { FeedbackPage } from './feedback/feedback.page';
import { NotificationsComponent } from './notifications/notifications.component';
import { RsvpPage } from './plan-details/rsvp/rsvp.page';
import { ProfilePopoverPage } from './profile/profile-popover/profile-popover.page';
import { getFirebaseSettings } from './utils/utils';

@NgModule({
  declarations: [
    AppComponent,
    FeedbackPage,
    PasswordLinkPage,
    ComposePage,
    UploadPage,
    RsvpPage,
    ProfilePopoverPage,
    NotificationsComponent,
    ActionDropdownComponent,
    ImageModalComponent,
    FilterDropdownComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(getFirebaseSettings(), window.appConfig.subdomain),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    FormsModule,
    Ionic4DatepickerModule,
    HttpClientModule,
    AngularEditorModule,
    NgxTippyModule,
    ImageCropperModule,
    CustomFieldsModule,
    SlackBlocksModule,
    CommunityMembershipRulesModule,
    DatetimePickerModule,
    ResponseInputModule,
    EmailEditorModule,
    AvatarModule,
    SharedPipesModule,
    MessagingModule,
    NgSelectModule,
    SharedDirectivesModule,
    EditUserTagModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    PlansService,
    UploadService,
    AuthService,
    ApiService,
    StockPhotosService,
    ScreenTrackingService,
    UserTrackingService,
    ThemeService,
    AnalyticsService,
    MessageService,
    UsersService,
    EventsService,
    TenantsService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 8083] : undefined,
    },
    {
      provide: TENANT_ID,
      useValue: window.appConfig.tenantId ?? null,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (authService) => () => new Promise((resolve) => authService.onAuthReady(() => resolve(true))),
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
