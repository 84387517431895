import { saveAs } from 'file-saver';
import firebase from 'firebase/app';
import * as _ from 'lodash';
import * as moment from 'moment';

import { CUSTOM_FIELD_TYPE_USER_ATTRIBUTE, OUTBOUND_RECIPIENT_TYPE } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { Organizer, Profile } from 'src/models';

const replacer = (_key: string, value: any) => {
  if (typeof value === 'boolean') {
    return value.toString();
  }

  if (typeof value === 'number') {
    return String(value);
  }

  if (_.isEmpty(value)) {
    return '';
  }

  if (value instanceof Date) {
    return moment(value).format();
  }

  if (value instanceof Array) {
    return JSON.stringify(value).replace(/,/g, ' ');
  }

  return value;
};

export const downloadCSV = (items: any[], columns: string[] | { id: string; label: string }[], filename: string) => {
  if (!items.length) {
    return;
  }

  const normalizedColumns = columns.map((column) => {
    if (typeof column === 'string') {
      return { id: column, label: column };
    }

    return column;
  });

  const csv = items.map((row) =>
    normalizedColumns
      .map((column) => {
        let value = _.get(row, column.id);

        if (value instanceof firebase.firestore.Timestamp) {
          value = value.toDate();
        }

        if (Array.isArray(value)) {
          value = value.join(',');
        }

        return JSON.stringify(value, replacer);
      })
      .join(',')
  );

  const escapedHeaders = normalizedColumns.map((column) => column.label.replace(/,/g, ''));

  csv.unshift(escapedHeaders.join(','));

  const csvArray = csv.join('\r\n');
  const blob = new Blob([csvArray], { type: 'text/csv' });

  saveAs(blob, `${filename}.csv`);
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }
  return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;
};

export const validateEmail = (email?: string) => {
  if (!email) {
    return;
  }

  const cleanEmail = email
    .toLowerCase()
    .replace(/^\s+|\s+$/g, '')
    .replace(',', '');

  if (!/(.+)@(.+){2,}\.(.+){2,}/.test(cleanEmail)) {
    return;
  }

  return cleanEmail;
};

export const durationStringFromMinutes = (minutes: number) => {
  const h = Math.floor(minutes / 60);
  const mins = minutes - h * 60;
  const minSuffix = mins === 0 ? `` : `${mins} min`;
  const hourPlurality = h > 1 ? 's ' : ' ';
  const hourPrefix = h === 0 ? `` : `${h} hour${hourPlurality}`;
  return `${hourPrefix}${minSuffix}`;
};

export const getHtmlPreviewText = (text: string, length = 220) => {
  if (!text) {
    return '';
  }

  const htmlFree = text
    .replace(/<div>/g, ' ')
    .replace(/<br>/g, ' ')
    .replace(/<[^>]*>/g, '')
    .replace(/&#160;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&#34;/g, '"');
  const substring = htmlFree.substring(0, 220);

  return substring.length >= length ? `${substring}...` : substring;
};

export function getCompanyCustomFieldId(field) {
  if (field.type === CUSTOM_FIELD_TYPE_USER_ATTRIBUTE) {
    return getAdditionalIdpFieldId(field.fieldKey);
  }

  return getCustomFieldId(field);
}

export function getCustomFieldId(field) {
  return `customFields.${field.id ?? _.camelCase(field.title)}`;
}

export function getAdditionalIdpFieldId(fieldName) {
  return `additionalIdpFields.${fieldName}`;
}

export function flattenAdditionalIdpFields(additionalIdpFields: Record<string, unknown>, prefix: string[] = []) {
  return Object.entries(additionalIdpFields).reduce((acc, [key, value]) => {
    // Exclude array fields for now.
    // TODO: Maybe figure out a way to handle these at some point.
    if (Array.isArray(value)) {
      return acc;
    }

    const newPrefix = [...prefix, key];

    if (typeof value === 'object' && value !== null) {
      return {
        ...acc,
        ...flattenAdditionalIdpFields(value as Record<string, unknown>, newPrefix),
      };
    }

    return {
      ...acc,
      [newPrefix.join('.')]: value,
    };
  }, {});
}

export function removeUndefinedFields(data: Record<string, any>) {
  return _.omitBy(data, (v) => v === undefined);
}

export function getFirebaseSettings() {
  const isDev = environment.environment === 'dev';
  const firebaseSettings = { ...environment.firebase };

  if (!isDev) {
    if (!window.appConfig.useLegacyAuthDomain) {
      firebaseSettings.authDomain = window.location.host;
    }
  }

  return firebaseSettings;
}

export interface Recipient {
  type?: typeof OUTBOUND_RECIPIENT_TYPE.COMMUNITY | typeof OUTBOUND_RECIPIENT_TYPE.EMAIL;
  id?: string;
  email?: string;
  name?: string;
  count?: number;
}

export function createCommunityRecipient(community: Organizer): Recipient {
  return {
    id: community.objectId ?? community.id ?? null,
    name: `${community.name} (${community.followerCount} member${community.followerCount === 1 ? '' : 's'})`,
    type: OUTBOUND_RECIPIENT_TYPE.COMMUNITY,
    count: community.followerCount,
  };
}

export function createUserRecipient(user: Profile): Recipient {
  return {
    id: user.id,
    name: `${user.name} (${user.email ?? user.phoneNumber})`,
    type: OUTBOUND_RECIPIENT_TYPE.USER,
  };
}

export function isUrl(str: string) {
  str = str.trim();

  if (!str.startsWith('https://') && !str.startsWith('http://')) {
    str += 'https://';
  }

  try {
    new URL(str);

    return true;
  } catch {
    return false;
  }
}

export function findMedian(arr: number[]) {
  if (!arr.length) {
    return undefined;
  }

  const s = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(s.length / 2);

  return s.length % 2 === 0 ? (s[mid - 1] + s[mid]) / 2 : s[mid];
}

export function sleep(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
