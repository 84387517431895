import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';

import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

const MIXPANEL_GROUP_KEY = 'tenants';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private mixpanelEnabled: boolean = false;

  constructor(private router: Router, private authService: AuthService) {}

  trackEvent(category: string, label: string, properties: Record<string, any> = {}) {
    gtag('event', 'click', {
      event_category: category,
      event_label: label,
      // value,
    });

    this.mixpanelTrack(label, {
      ...properties,
      pageContext: category,
    });
  }

  mixpanelTrack(event, properties) {
    if (!this.mixpanelEnabled) {
      return;
    }

    mixpanel.track(event, properties);
  }

  initMixpanel() {
    // TODO: For newco - Add a new mixpanel API key to re-enable this functionality. See the environtment files.
    if (!environment.mixPanelToken) {
      return;
    }

    // Skip analytics if we are impersonating someone.
    if (this.authService.idTokenResult?.claims?.impersonatedBy) {
      return;
    }

    this.mixpanelEnabled = true;

    // Init mixpanel
    mixpanel.init(environment.mixPanelToken, { debug: !environment.production });

    const tenantId = this.authService.tenantId;
    const profile = this.authService.profile;
    const tenant = this.authService.tenant;

    if (!profile) {
      return;
    }

    mixpanel.identify(this.authService.userId);
    mixpanel.people.set({
      tenantId,
      isTenantAdmin: this.authService.isCompanyAdmin,
      $email: profile.email,
      $name: profile.name,
    });

    if (tenant) {
      // NOTE: This won't actually do anything until we upgrade our plan. Current plan doesn't support groups.
      mixpanel.set_group(MIXPANEL_GROUP_KEY, tenantId);
      mixpanel.get_group(MIXPANEL_GROUP_KEY, tenantId).set({
        name: tenant.name,
      });
    }
  }

  init(trackingId: string) {
    this.initMixpanel();

    // Init google analytics
    if (!trackingId) {
      console.log('No tracking id; google analytics disabled');
      return;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', trackingId, {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML =
        `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '` +
        trackingId +
        `', {'send_page_view': false});
            `;
      document.head.appendChild(script2);
    } catch (err) {
      console.error('Error initializing google analytics: ', err);
    }
  }
}
